import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import { IFlowData } from '../../../../store/ducks/chats/types';

import { Container, List, ListItem, Title } from './styles';

interface IProps {
  data: IFlowData;
}

interface IOption {
  id: number;
  value: string;
  checked: boolean;
}

export interface ICheckBoxListHandles {
  getCheckedItems(): string[];
}

const CheckBoxList: React.ForwardRefRenderFunction<
  ICheckBoxListHandles,
  IProps
> = ({ data }, ref) => {
  const [options, setOptions] = useState<IOption[]>(
    data.dynamicField?.options.map(opt => ({
      id: opt.id,
      value: opt.value,
      checked: false
    })) ?? ([] as IOption[])
  );

  const getCheckedItems = useCallback(() => {
    return options.filter(opt => opt.checked).map(opt => opt.value) ?? [];
  }, [options]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setOptions(current =>
        current.map(opt => {
          if (opt.value === event.target.value) {
            opt.checked = event.target.checked;
          }

          return opt;
        })
      );
    },
    []
  );

  useImperativeHandle(ref, () => {
    return {
      getCheckedItems
    };
  });

  return (
    <Container>
      {data.placeholder && <Title>{data.placeholder}</Title>}
      <List>
        {options.map(option => (
          <ListItem key={`item-${option.id}`}>
            <input
              type="checkbox"
              id={`opt-${option.id}`}
              name={data.tag}
              value={option.value}
              checked={option.checked}
              onChange={handleChange}
            />
            <label htmlFor={`opt-${option.id}`}>{option.value}</label>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default forwardRef(CheckBoxList);
