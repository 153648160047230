import { darken } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { ChatPosition } from '.';
import { IChatConfigImageProps } from '../../store/ducks/chats/types';

interface IAvatarProps {
  size: number;
  avatarType: 'bot' | 'user' | 'initial' | 'collapse';
  image?: IChatConfigImageProps;
  allowAnimation: boolean;
  colorChat?: string;
}

interface IChatBubbleProps {
  position: ChatPosition;
  size: number;
  margins: {
    x: number;
    y: number;
  };
}

const shakeAnimation = () => keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const buttonAnimation = () => keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const bubbleAnimation = () => keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const AvatarButton = styled.button<IAvatarProps>`
  border: none;
  background: transparent;
  padding: 0;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;

  ${props =>
    props.avatarType === 'initial' &&
    props.allowAnimation &&
    css`
      opacity: 0;
      animation: ${buttonAnimation} 1s ease-in-out forwards,
        ${shakeAnimation} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    `}

  ${props =>
    props.image &&
    css`
      background-color: ${props.image.background_color};
      background-image: url(${props.image.image_url});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: ${props.image.border_radius ?? 50}%;
    `}

  ${props =>
    props.avatarType === 'collapse' &&
    props.image &&
    css`
      border-radius: ${props.image.border_radius ?? 50}%;
    `}

    ${props =>
    props.avatarType === 'collapse' &&
    !props.image &&
    css`
      border-radius: 50%;
      transform: scale(0.9);
    `}

  ${props =>
    props.avatarType === 'collapse' &&
    css`
      background: ${props.colorChat || '#409dca'};

      &:hover {
        background: ${darken(0.08, props.colorChat || '#409dca')};
      }

      svg {
        fill: #fff;
        width: 32px;
        height: 32px;
      }
    `}
`;

export const AvatarContainer = styled.div<IAvatarProps>`
  border: none;
  background: transparent;
  padding: 0;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;

  ${props =>
    props.avatarType === 'initial' &&
    css`
      opacity: 0;
      animation: ${buttonAnimation} 1s ease-in-out forwards,
        ${shakeAnimation} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    `}

  ${props =>
    props.image &&
    css`
      background-color: ${props.image.background_color};
      background-image: url(${props.image.image_url});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: ${props.image.border_radius ?? 50}%;
    `}
`;

export const Badge = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px !important;
`;

export const ChatBubble = styled.div<IChatBubbleProps>`
  min-height: 80px;
  width: 300px;
  max-width: 300px;
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  animation: ${bubbleAnimation} 0.6s ease-in-out forwards;
  animation-delay: 0.5s;

  transform-origin: ${props => props.position};
  position: absolute;

  div.bubble {
    background: #fff;
    border-radius: 5px;
    padding: 16px;
    position: relative;
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px;
    color: #333;
    z-index: 300;
    cursor: default;
  }

  div.arrow {
    background-color: #fff;
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    z-index: 310;
  }

  @media (max-width: 430px) {
    padding: 4px 10px;
    max-width: ${props =>
      `calc(100vw - ${props.size}px - ${props.margins.x}px)`};

    div.bubble {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 21px;
    }

    div.arrow {
      height: 12px;
      width: 12px;
    }
  }

  ${props =>
    props.position === 'bottom left' &&
    css`
      justify-content: flex-start;
      bottom: 0;
      left: 80px;

      div.arrow {
        left: 14px;
        bottom: 29px;
        transform: rotate(135deg);
        box-shadow: 3px 1px 2px rgba(0, 0, 0, 0.06),
          3px 1px 2px rgba(0, 0, 0, 0.1);

        @media (max-width: 430px) {
          left: 4px;
        }
      }
    `}

  ${props =>
    props.position === 'bottom right' &&
    css`
      justify-content: flex-end;
      right: 80px;
      bottom: 0;

      div.arrow {
        right: 14px;
        bottom: 29px;
        transform: rotate(-45deg);
        box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.06),
          1px 3px 2px rgba(0, 0, 0, 0.1);

        @media (max-width: 430px) {
          right: 4px;
        }
      }
    `}

  ${props =>
    props.position === 'top left' &&
    css`
      justify-content: flex-start;
      top: 0;
      left: 80px;

      div.arrow {
        left: 14px;
        bottom: 29px;
        transform: rotate(135deg);
        box-shadow: 3px 1px 2px rgba(0, 0, 0, 0.06),
          3px 1px 2px rgba(0, 0, 0, 0.1);

        @media (max-width: 430px) {
          left: 4px;
        }
      }
    `}

  ${props =>
    props.position === 'top right' &&
    css`
      justify-content: flex-end;
      top: 0;
      right: 80px;

      div.arrow {
        right: 14px;
        bottom: 29px;
        transform: rotate(-45deg);
        box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.06),
          1px 3px 2px rgba(0, 0, 0, 0.1);

        @media (max-width: 430px) {
          right: 4px;
        }
      }
    `}
`;
