import React from 'react';
import ReactDOM from 'react-dom';
import { useImageModal } from '../../hooks/useImageModal';
import { modalRootId, ModalRootStyle, Container } from './styles';

export interface IProps {
  open?: boolean;
  imageUrl: string;
}

export const ModalRootImage: React.FC = () => {
  return <div id={modalRootId} />;
};

export const PortalModalImage: React.FC = ({ children }) => {
  const modalRootEl = document
    .querySelector('.app-shadow')
    ?.shadowRoot?.getElementById(modalRootId);

  if (!modalRootEl) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <>
      {children}
      <ModalRootStyle />
    </>,
    modalRootEl
  );
};

const ImageModal: React.FC<IProps> = ({ imageUrl }) => {
  const { switchImageModal, statusModal } = useImageModal();

  const handleSwitchModal = () => {
    switchImageModal(imageUrl);
  };

  return (
    <Container openModal={statusModal} onClick={handleSwitchModal}>
      <img alt={imageUrl} src={imageUrl} />
    </Container>
  );
};

export default ImageModal;
