import styled, { keyframes, css } from 'styled-components';
import { IProps } from '.';

const pulse = keyframes`
  0% { transform: scale(1); opacity: 1 }
  45% { transform: scale(0.1); opacity: 0.7 }
  80% { transform: scale(1); opacity: 1 }
`;

interface PointerProps {
  nth: number;
}

export const Pointer = styled.div<PointerProps>`
  border-radius: 100%;
  display: inline-block;
  animation: ${({ nth }) =>
    css`
      ${pulse} 0.75s ${nth * 0.12}s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
    `};
  animation-fill-mode: both;
`;

export const Container = styled.div<IProps>`
  ${({ size, color, margin }) => css`
    div {
      background-color: ${color};
      width: ${size}px;
      height: ${size}px;
      margin: ${margin}px;
    }
  `};
`;
