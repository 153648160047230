import React, { useState, createContext, useContext, useCallback } from 'react';
import ConfirmModal, {
  ConfirmModalProps,
  PortalModal
} from '../components/ConfirmModal';

interface ConfirmModalContextData {
  createConfirmModal(config: ConfirmModalConfig): void;
}

type ActionCallback = () => Promise<'success' | 'error'>;

type ConfirmModalConfig = Omit<
  ConfirmModalProps,
  'onCancel' | 'onConfirm' | 'onSecondary'
> & {
  onConfirm: ActionCallback;
  onSecondary: ActionCallback;
};

const ConfirmModalContext = createContext<ConfirmModalContextData>(
  {} as ConfirmModalContextData
);

const ConfirmModalProvider: React.FC = ({ children }) => {
  const [modalConfigs, setModalConfigs] = useState<Omit<
    ConfirmModalProps,
    'open'
  > | null>(null);

  const handleConfirmAction = useCallback(async (actionCb: ActionCallback) => {
    const confirmationStatus = await actionCb();
    if (confirmationStatus === 'success') {
      setModalConfigs(null);
    }
  }, []);

  const handleSecondaryAction = useCallback(
    async (actionCb: ActionCallback | undefined) => {
      if (actionCb) {
        const actionStatus = await actionCb();
        if (actionStatus === 'success') {
          setModalConfigs(null);
        }
      }
    },
    []
  );

  const handleCreateModal = useCallback(
    (config: ConfirmModalConfig) => {
      setModalConfigs({
        ...config,
        onCancel: () => setModalConfigs(null),
        onConfirm: () => handleConfirmAction(config.onConfirm),
        onSecondary: config.onSecondary
          ? () => handleSecondaryAction(config.onSecondary)
          : undefined
      });
    },
    [handleConfirmAction, handleSecondaryAction]
  );

  return (
    <ConfirmModalContext.Provider
      value={{
        createConfirmModal: handleCreateModal
      }}
    >
      {children}
      {modalConfigs && (
        <PortalModal>
          <ConfirmModal {...modalConfigs} />
        </PortalModal>
      )}
    </ConfirmModalContext.Provider>
  );
};

function useConfirmModal(): ConfirmModalContextData {
  const context = useContext(ConfirmModalContext);
  if (!context) {
    throw new Error(
      'useConfirmModal must be used within an ConfirmModalProvider'
    );
  }
  return context;
}

export { ConfirmModalProvider, useConfirmModal };
