import { action } from 'typesafe-actions';
import {
  ChatTypes,
  IAddAnswerSuccess,
  IAddAnswerSuccessAction,
  IAddOptionAnswer,
  IAddOptionAnswerAction,
  IAddQuestionAnswer,
  IAddQuestionAnswerAction,
  IAddQuestionRequest,
  IAddQuestionRequestAction,
  IAddTimelineItemRequest,
  IAddTimelineItemRequestAction,
  IAddTimelineItemSuccess,
  IAddTimelineItemSuccessAction,
  IClearStorageAction,
  IExecuteAction,
  IExecuteActionAction,
  IFetchNextScriptItemAction,
  ILoadChatFailureAction,
  ILoadChatRequest,
  ILoadChatRequestAction,
  ILoadChatSuccess,
  ILoadChatSuccessAction,
  ILoadExternalInfoRequest,
  ILoadExternalInfoRequestAction,
  ILoadExternalInfoSuccess,
  ILoadExternalInfoSuccessAction,
  IRenderChat,
  IRestartChat,
  IRestartChatAction,
  ISaveInteractionAction,
  ISetAwaitToRender,
  ISetAwaitToRenderAction,
  ISetChatAsClosedAction,
  ISetChatAsFinishedAction,
  ISetChatAsRemoved,
  ISetError,
  ISetErrorAction,
  ISetUserInteractedAction,
  ISetZendeskChatAsOpen,
  ISetZendeskChatAsOpenAction,
  ISetZendeskInitialMessageAsSent,
  ISetZendeskInitialMessageAsSentAction
} from './types';

export const addAnswerSuccess = (
  payload: IAddAnswerSuccess
): IAddAnswerSuccessAction => action(ChatTypes.ADD_ANSWER_SUCCESS, payload);

export const addOptionAnswer = (
  payload: IAddOptionAnswer
): IAddOptionAnswerAction => action(ChatTypes.ADD_OPTION_ANSWER, payload);

export const addQuestionRequest = (
  payload: IAddQuestionRequest
): IAddQuestionRequestAction => action(ChatTypes.ADD_QUESTION_REQUEST, payload);

export const addQuestionAnswer = (
  payload: IAddQuestionAnswer
): IAddQuestionAnswerAction => action(ChatTypes.ADD_QUESTION_ANSWER, payload);

export const addTimelineItemRequest = (
  payload: IAddTimelineItemRequest
): IAddTimelineItemRequestAction =>
  action(ChatTypes.ADD_TIMELINE_ITEM_REQUEST, payload);

export const addTimelineItemSuccess = (
  payload: IAddTimelineItemSuccess
): IAddTimelineItemSuccessAction =>
  action(ChatTypes.ADD_TIMELINE_ITEM_SUCCESS, payload);

export const clearChat = () => action(ChatTypes.CLEAR_CHAT);

export const clearStorage = (): IClearStorageAction =>
  action(ChatTypes.CLEAR_STORAGE);

export const closeZendeskChat = () => action(ChatTypes.CLOSE_ZENDESK_CHAT);

export const executeAction = (payload: IExecuteAction): IExecuteActionAction =>
  action(ChatTypes.EXECUTE_ACTION, payload);

export const fetchNextScriptItem = (): IFetchNextScriptItemAction =>
  action(ChatTypes.FETCH_NEXT_SCRIPT_ITEM);

export const loadChatFailure = (): ILoadChatFailureAction =>
  action(ChatTypes.LOAD_CHAT_FAILURE);

export const loadChatRequest = (
  payload: ILoadChatRequest
): ILoadChatRequestAction => action(ChatTypes.LOAD_CHAT_REQUEST, payload);

export const loadChatSuccess = (
  payload: ILoadChatSuccess
): ILoadChatSuccessAction => action(ChatTypes.LOAD_CHAT_SUCCESS, payload);

export const loadExternalInfoRequest = (
  payload: ILoadExternalInfoRequest
): ILoadExternalInfoRequestAction =>
  action(ChatTypes.LOAD_EXTERNAL_INFO_REQUEST, payload);

export const loadExternalInfoSuccess = (
  payload: ILoadExternalInfoSuccess
): ILoadExternalInfoSuccessAction =>
  action(ChatTypes.LOAD_EXTERNAL_INFO_SUCCESS, payload);

export const openZendeskChat = () => action(ChatTypes.OPEN_ZENDESK_CHAT);

export const removeChat = () => action(ChatTypes.REMOVE_CHAT);

export const renderChat = (payload: IRenderChat) =>
  action(ChatTypes.RENDER_CHAT, payload);

export const restartChat = (payload: IRestartChat): IRestartChatAction =>
  action(ChatTypes.RESTART_CHAT, payload);

export const saveInteraction = (): ISaveInteractionAction =>
  action(ChatTypes.SAVE_INTERACTION);

export const setAwaitToRender = (
  payload: ISetAwaitToRender
): ISetAwaitToRenderAction => action(ChatTypes.SET_AWAIT_TO_RENDER, payload);

export const setChatAsRemoved = (
  payload: ISetChatAsRemoved
): ISetChatAsClosedAction => action(ChatTypes.SET_CHAT_AS_REMOVED, payload);

export const setChatAsFinished = (): ISetChatAsFinishedAction =>
  action(ChatTypes.SET_CHAT_AS_FINISHED);

export const setError = (payload: ISetError): ISetErrorAction =>
  action(ChatTypes.SET_ERROR, payload);

export const setUserInteracted = (): ISetUserInteractedAction =>
  action(ChatTypes.SET_USER_INTERACTED);

export const setZendeskChatAsOpen = (
  payload: ISetZendeskChatAsOpen
): ISetZendeskChatAsOpenAction =>
  action(ChatTypes.SET_ZENDESK_CHAT_AS_OPEN, payload);

export const setZendeskInitialMessageAsSent = (
  payload: ISetZendeskInitialMessageAsSent
): ISetZendeskInitialMessageAsSentAction =>
  action(ChatTypes.SET_ZENDESK_INITIAL_MESSAGE_AS_SENT, payload);

export const ChatActions = {
  addAnswerSuccess,
  addOptionAnswer,
  addQuestionRequest,
  addQuestionAnswer,
  addTimelineItemRequest,
  addTimelineItemSuccess,
  clearChat,
  clearStorage,
  closeZendeskChat,
  executeAction,
  fetchNextScriptItem,
  loadChatFailure,
  loadChatRequest,
  loadChatSuccess,
  loadExternalInfoRequest,
  loadExternalInfoSuccess,
  openZendeskChat,
  removeChat,
  renderChat,
  restartChat,
  saveInteraction,
  setAwaitToRender,
  setChatAsFinished,
  setChatAsRemoved,
  setError,
  setUserInteracted,
  setZendeskChatAsOpen,
  setZendeskInitialMessageAsSent
};
