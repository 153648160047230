import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useZendesk } from '../../../hooks/useZendesk';
import { ChatActions } from '../../../store/ducks/chats/actions';
import MessageBox from '../../MessageBox';
import { Container } from './styles';

interface IProps {
  id: string;
  last: boolean;
  finished: boolean;
}

const RedirectZendeskBox: React.FC<IProps> = ({ id, last, finished }) => {
  const { getZendeskSettings } = useZendesk();
  const [secondsToRedirect, setSecondsToRedirect] = useState(4);
  const dispatch = useDispatch();

  const setZendeskChatAsOpen = () => {
    dispatch(ChatActions.openZendeskChat());
  };
  const startCountToRedirectToZendesk = () => {
    if (secondsToRedirect > 0) {
      if (finished) {
        setSecondsToRedirect(0);
      } else {
        setSecondsToRedirect(oldSeconds => {
          if (oldSeconds > 1) {
            setTimeout(startCountToRedirectToZendesk, 1000);
          }
          return oldSeconds - 1;
        });
      }
    }
  };

  useEffect(() => {
    startCountToRedirectToZendesk();
  }, []);

  useEffect(() => {
    if (secondsToRedirect === 0) {
      if ((window as any).zE) {
        (window as any).zE('webWidget', 'updateSettings', getZendeskSettings());
      }
      localStorage.setItem('zendesk_chat_is_finished', '');
      setZendeskChatAsOpen();
    }
  }, [secondsToRedirect]);

  const msgRedirect = useMemo(() => {
    return `Redirecionado para um atendente em ${secondsToRedirect}`;
  }, [secondsToRedirect]);

  return (
    <Container>
      <MessageBox
        id={id}
        type="ZENDESK"
        content={msgRedirect}
        showAvatar={last}
      />
    </Container>
  );
};

export default RedirectZendeskBox;
