import { darken } from 'polished';
import styled from 'styled-components';

interface IContainerProps {
  colorChat: string;
}

export const Container = styled.form<IContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
  background-color: #f4f5f8;
  border: 1px solid #eee;
  border-radius: 8px;
  margin: 0 8px 8px 8px;

  input {
    background: transparent;
    border: 0;
    color: #333;
    flex: 1;
    font-size: 16px;
    padding: 10px 12px;

    &::placeholder {
      color: #8e8e8e;
    }
  }

  button {
    background: ${props => props.colorChat};
    border: none;
    height: 30px;
    width: 30px;
    margin: 0 !important;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;

    align-self: flex-end;
    margin-bottom: 4px !important;

    &:hover {
      background: ${props => darken(0.08, props.colorChat || '#409dca')};
    }
  }
`;
