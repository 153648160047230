import { decompress } from 'lzutf8';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdClose, MdRefresh } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useAvatar } from '../../hooks/avatar';
import { useConfirmModal } from '../../hooks/useConfirmModal';
import { useFetch } from '../../hooks/useFetch';
import { useZendesk } from '../../hooks/useZendesk';
import { IApplicationState as AppState } from '../../store';
import { ChatActions } from '../../store/ducks/chats/actions';
import {
  ErrorType,
  FlowItemType,
  IChat,
  IChatConfigVisibility,
  IFlowOptionData
} from '../../store/ducks/chats/types';
import Avatar from '../Avatar';
import { ModalRoot } from '../ConfirmModal';
import MessageBox from '../MessageBox';
import QuestionBox from './QuestionBox';
import RedirectBox from './RedirectBox';
import RedirectZendeskBox from './RedirectZendeskBox';
import {
  CloseButton,
  Container,
  ErrorBox,
  FinishedMessage,
  FooterContainer,
  Header,
  HeaderContent,
  Messages,
  Wrapper
} from './styles';

type ChatPosition = 'top left' | 'top right' | 'bottom left' | 'bottom right';

type ChatMargins = {
  x: number;
  y: number;
};

const Chat: React.FC = () => {
  const chatDiv = document.getElementById('chatsales-widget');
  const chatId =
    chatDiv?.dataset.chatId ?? process.env.REACT_APP_DEBUG_CHATID ?? '';
  const chatPosition = chatDiv?.dataset.position ?? 'bottom right';
  const chatMargins = chatDiv?.dataset.margins ?? '16 16';
  const previewId = chatDiv?.dataset.previewId ?? '';
  const silent = Boolean(chatDiv?.dataset.silent) ?? false;
  const { showChatZendesk } = useZendesk();
  const fullscreen = Boolean(chatDiv?.dataset.fullscreen) ?? false;
  // const path = previewId ? `flows/preview/${previewId}` : `chats/${chatId}`;
  const [path, setPath] = useState(
    previewId ? `flows/preview/${previewId}` : `chats/${chatId}`
  );
  const [execute, setExecute] = useState(false);

  const dispatch = useDispatch();
  const { setConfig } = useAvatar();
  const { createConfirmModal } = useConfirmModal();
  const { data, error: fetchError } = useFetch<IChat>(path);

  const error = useSelector((state: AppState) => state.chats.error);
  const typing = useSelector((state: AppState) => state.chats.loading);
  const question = useSelector((state: AppState) => state.chats.question);
  const timeline = useSelector((state: AppState) => state.chats.timeline);
  const finished = useSelector((state: AppState) => state.chats.finished);
  const removed = useSelector((state: AppState) => state.chats.removed);
  const [executedParams, setExecutedParams] = useState<string[]>([]);
  const userInteracted = useSelector(
    (state: AppState) => state.chats.userInteracted
  );
  const zendeskChatOpen = useSelector(
    (state: AppState) => state.chats.zendeskChatOpen
  );
  const awaitToRender = useSelector(
    (state: AppState) => state.chats.awaitToRender
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const [chat, setChat] = useState<IChat>();
  const [loading, setLoading] = useState(true);
  const [messageBoxOptionSelected, setMessageBoxOptionSelected] = useState<
    string[]
  >([]);
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState<ChatPosition>('bottom right');
  const [margins, setMargins] = useState<ChatMargins>({ y: 16, x: 16 });
  const [initialRenderRequested, setInitialRenderRequested] = useState(false);
  const [zendeskChatOpened, setZendeskChatOpened] = useState(false);

  const handleAnswerSubmit = useCallback(
    (answer: string) => {
      dispatch(ChatActions.addQuestionAnswer({ answer }));
    },
    [dispatch]
  );

  const handleClickRestartChat = useCallback(() => {
    if (chat) {
      setMessageBoxOptionSelected([]);
      dispatch(ChatActions.restartChat({ chat }));
    }
  }, [dispatch, chat]);

  const handleOptionClick = useCallback(
    (id: string, option: IFlowOptionData, tag?: string) => {
      const messageOptions = messageBoxOptionSelected;
      messageOptions.push(id);
      if (option.link) {
        window.open(option.link, '_blank');
      }
      if (option.sourceConnectionId) {
        dispatch(ChatActions.addOptionAnswer({ id, option, tag }));
      } else {
        dispatch(ChatActions.setChatAsFinished());
      }
      setMessageBoxOptionSelected(messageOptions);
    },
    [dispatch, messageBoxOptionSelected]
  );

  const handleToggleChat = useCallback(
    (forceOpen = false) => {
      let isOpening = false;
      setOpen(oldOpen => {
        if (forceOpen) return true;
        isOpening = !oldOpen;
        return isOpening;
      });
      if (chat && (isOpening || forceOpen)) {
        dispatch(ChatActions.loadChatRequest({ chat }));
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 700);
      }
      dispatch(ChatActions.setUserInteracted());
    },
    [chat, dispatch]
  );

  const handleOpenChat = useCallback(() => {
    if (!open) {
      handleToggleChat(true);
    }
  }, [open, handleToggleChat]);

  const handleCloseChat = useCallback(() => {
    if (open) {
      handleToggleChat();
    }
  }, [open, handleToggleChat]);

  const handleLoadChat = useCallback(() => {
    dispatch(ChatActions.setChatAsRemoved({ removed: false }));
  }, [dispatch]);

  const handleRemoveChat = useCallback(() => {
    dispatch(ChatActions.removeChat());
  }, [dispatch]);

  const handleResetChat = useCallback(
    (chatObject: IChat) => {
      dispatch(ChatActions.restartChat({ chat: chatObject }));
      handleLoadChat();
    },
    [dispatch, handleLoadChat]
  );

  const handleClickRemoveChat = useCallback(() => {
    createConfirmModal({
      message: (
        <div>
          <p>
            <b>Tem certeza que deseja fechar o chat?</b>
          </p>
          <br />
          <small>
            Ao fechar, toda a conversa será apagada e não poderá ser recuperada
            mais tarde.
          </small>
        </div>
      ),
      confirmText: 'Fechar',
      onConfirm: async () => {
        handleRemoveChat();
        return 'success';
      },
      secondaryText: 'Minimizar',
      onSecondary: async () => {
        handleToggleChat();
        return 'success';
      },
      confirmColor: chat?.config?.color_chat ?? 'rgb(64, 157, 202)'
    });
  }, [
    chat?.config?.color_chat,
    createConfirmModal,
    handleRemoveChat,
    handleToggleChat
  ]);

  const isBlocked = useCallback((config?: IChatConfigVisibility) => {
    const host = window.location.origin;
    const baseWhitleList = [
      'https://app.leadlovers.com',
      'https://chatsales-web.leadlovers.com'
    ];

    if (baseWhitleList.includes(host)) {
      return false;
    }

    const currentUrl = host + window.location.pathname;
    const currentUrlOtherProtocol = currentUrl.includes('https://')
      ? currentUrl.replace('https://', 'http://')
      : currentUrl.replace('http://', 'https://');

    const isInWhiteList =
      !config?.whitelisted_urls?.length ||
      config?.whitelisted_urls?.some(
        url =>
          currentUrl.startsWith(url) || currentUrlOtherProtocol.startsWith(url)
      );

    if (!isInWhiteList) {
      return true;
    }

    const isInBlackList = config?.blacklisted_urls?.some(
      url =>
        currentUrl.startsWith(url) || currentUrlOtherProtocol.startsWith(url)
    );

    if (isInBlackList) {
      return true;
    }

    return false;
  }, []);

  function ScrollMessagesToEnd() {
    setTimeout(() => {
      if (
        (timeline?.length ?? 0) > 0 &&
        messagesContainerRef &&
        messagesContainerRef.current
      ) {
        messagesContainerRef.current.scrollTo({
          top: messagesContainerRef.current.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }
    }, 1100);
  }
  const executeScript = (html: string) => {
    if (executedParams.includes(html)) {
      return;
    }

    const scriptPattern = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    let match;

    // eslint-disable-next-line no-cond-assign
    while ((match = scriptPattern.exec(html)) !== null) {
      const scriptContent = match[1];
      const scriptElement = document.createElement('script');
      scriptElement.textContent = scriptContent;
      document.body.appendChild(scriptElement);
    }
    setExecutedParams([...executedParams, html]);
  };
  useEffect(() => {
    setZendeskChatOpened(oldOpened => {
      return (
        oldOpened ||
        zendeskChatOpen ||
        Boolean(!localStorage.getItem('zendesk_chat_is_finished'))
      );
    });
  }, [zendeskChatOpen]);

  useEffect(() => {
    if (!loading && !initialRenderRequested && chat) {
      const render_delay = chat?.config?.render_delay ?? 0;
      dispatch(ChatActions.renderChat({ render_delay }));
      setInitialRenderRequested(true);
    }
  }, [loading, initialRenderRequested, dispatch, chat]);

  useEffect(() => {
    setTimeout(() => {
      if (
        (timeline?.length ?? 0) > 0 &&
        messagesEndRef &&
        messagesEndRef.current
      ) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  }, [timeline, question, error]);

  useEffect(() => {
    if (
      chatPosition === 'bottom left' ||
      chatPosition === 'bottom right' ||
      chatPosition === 'top left' ||
      chatPosition === 'top right'
    ) {
      setPosition(chatPosition);
    }
  }, [chatPosition]);

  useEffect(() => {
    if (chatMargins) {
      const [y, x] = chatMargins.split(' ');
      setMargins({
        y: isNaN(Number(y)) ? 16 : parseInt(y, 10),
        x: isNaN(Number(x)) ? 16 : parseInt(x, 10)
      });
    }
  }, [chatMargins]);

  useEffect(() => {
    if (fullscreen && !!chat) {
      handleOpenChat();
    }
  }, [fullscreen, handleOpenChat, chat]);

  useEffect(() => {
    if (data && !fetchError && !isBlocked(data.config?.visibility)) {
      const rawFlow = data.flow.toString();
      const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      const decompressedFlow = base64regex.test(rawFlow)
        ? decompress(rawFlow, {
            inputEncoding: 'Base64'
          })
        : rawFlow;

      setChat({
        ...data,
        flow: JSON.parse(decompressedFlow)
      });
      //  console.log(JSON.parse(decompressedFlow), 'desconpress')
      setConfig(data.config);
    }
    setLoading(false);
  }, [data, fetchError, isBlocked, setConfig]);

  useEffect(() => {
    // if (chat) handleResetChat(chat);
    const behaviorWhenClose = chat?.config?.behavior_when_close_chat?.type;
    if (behaviorWhenClose === 'always-show' && !silent) {
      // resetar o chat caso a ultima escolha for 'falar com atendente' e o chat zendesk estiver finalizado
      if (
        timeline[timeline.length - 1]?.zendeskChat?.zendeskKey &&
        chat &&
        (!zendeskChatOpen || localStorage.getItem('zendesk_chat_is_finished'))
      ) {
        setMessageBoxOptionSelected([]);
        handleResetChat(chat);
        console.info('[CHATBOT]: chat reiniciado');
        return;
      }
      handleLoadChat();
    } else if (behaviorWhenClose === 'reset' && chat) {
      handleResetChat(chat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, handleLoadChat, handleResetChat, silent, zendeskChatOpen]);

  useEffect(() => {
    if (removed || awaitToRender) {
      return;
    }
    const event = new CustomEvent('renderChat');
    window.dispatchEvent(event);
  }, [loading, chat, removed, awaitToRender, userInteracted]);

  useEffect(() => {
    if (!chat?.id) {
      return;
    }
    const event = new CustomEvent('loadChat', {
      detail: {
        chatId: chat.id
      }
    });
    window.dispatchEvent(event);
  }, [chat?.id]);

  useEffect(() => {
    function changeChatListener(e: any) {
      const newChatId = e?.detail?.chat;

      if (newChatId) {
        setPath(`chats/${newChatId}`);
        if (chat) handleResetChat(chat);
      }
    }
    window.addEventListener('changeChat', changeChatListener);
    return () => {
      window.removeEventListener('changeChat', changeChatListener);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('toggleChat', handleToggleChat, false);
    window.addEventListener('openChat', handleOpenChat, false);
    window.addEventListener('closeChat', handleCloseChat, false);
    window.addEventListener('hideChat', handleRemoveChat, false);
    window.addEventListener('showChat', handleLoadChat, false);
    return () => {
      window.removeEventListener('toggleChat', handleToggleChat);
      window.removeEventListener('openChat', handleOpenChat);
      window.removeEventListener('closeChat', handleCloseChat);
      window.addEventListener('hideChat', handleRemoveChat);
      window.addEventListener('showChat', handleLoadChat);
    };
  }, [
    handleCloseChat,
    handleLoadChat,
    handleOpenChat,
    handleRemoveChat,
    handleToggleChat
  ]);

  useEffect(() => {
    if (
      zendeskChatOpen &&
      chat?.config &&
      !localStorage.getItem('zendesk_chat_is_finished')
    ) {
      showChatZendesk(timeline, chat.config.attendant_name || 'Atendente 01');
    }
  }, [zendeskChatOpen, showChatZendesk, timeline, chat?.config]);

  if ((removed && !zendeskChatOpen) || awaitToRender || zendeskChatOpen) {
    return <></>;
  }
  return (
    <Wrapper position={position} margins={margins} fullscreen={fullscreen}>
      <Container open={open} className="shadow-2">
        <Header
          colorChat={chat?.config?.color_chat || 'rgb(64, 157, 202)'}
          className="shadow-2"
        >
          <Avatar size={60} />
          <HeaderContent active={chat?.active ?? true}>
            <h1>{chat?.config?.attendant_name || 'Atendente 01'}</h1>
            <div>
              <div className="status">&nbsp;</div>
              <div>{chat?.active ? 'Online' : 'Offline'}</div>
            </div>
          </HeaderContent>
          {!fullscreen && (
            <CloseButton
              type="button"
              className="shadow-2"
              onClick={handleClickRemoveChat}
              colorChat={chat?.config?.color_chat || 'rgb(64, 157, 202)'}
            >
              <MdClose />
            </CloseButton>
          )}
        </Header>
        {error?.type !== ErrorType.NotFound &&
          error?.type !== ErrorType.InternalServerError && (
            <Messages ref={messagesContainerRef}>
              {timeline.map((item, index) => (
                <React.Fragment
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.id}_${index}`}
                >
                  {item.type !== 'ACTION' && (
                    <MessageBox
                      id={item.id}
                      type={item.type}
                      content={item.content}
                      tag={item.tag}
                      options={item.options}
                      showAvatar={
                        item.type === 'QUESTION' ||
                        !!item.options ||
                        (item.last && finished)
                      }
                      onOptionClick={handleOptionClick}
                      colorChat={chat?.config?.color_chat || '#409dca'}
                    />
                  )}
                  {item.redirect && (
                    <RedirectBox
                      id={item.id}
                      redirect={item.redirect}
                      last={item.last}
                      finished={finished}
                    />
                  )}
                  {item.zendeskChat && (
                    <RedirectZendeskBox
                      id={item.id}
                      last={item.last}
                      finished={finished}
                    />
                  )}
                  {item.createScript && executeScript(item.createScript)}
                </React.Fragment>
              ))}
              {typing && (
                <MessageBox
                  id="loading"
                  type={FlowItemType.LOADING}
                  showAvatar
                  colorChat={chat?.config?.color_chat || '#409dca'}
                />
              )}
              {error?.type === ErrorType.BadRequest && (
                <MessageBox
                  id="error"
                  type={FlowItemType.ERROR}
                  content={error.message}
                  showAvatar={false}
                />
              )}
              <div style={{ height: '1px' }} ref={messagesEndRef}>
                &nbsp;
              </div>
              {finished && (
                <FinishedMessage>
                  <span>Conversa finalizada.</span>
                  <div className="buttons-row">
                    <button type="button" onClick={handleClickRestartChat}>
                      <MdRefresh />
                      Reiniciar
                    </button>
                    {!fullscreen && (
                      <>
                        <hr />
                        <button type="button" onClick={handleClickRemoveChat}>
                          <MdClose />
                          Fechar
                        </button>
                      </>
                    )}
                  </div>
                </FinishedMessage>
              )}
              <ModalRoot />
            </Messages>
          )}
        <QuestionBox
          question={question}
          onSubmit={handleAnswerSubmit}
          onFocus={ScrollMessagesToEnd}
          colorChat={chat?.config?.color_chat || 'rgb(64, 157, 202)'}
        />
        {error && error?.type !== ErrorType.BadRequest && (
          <ErrorBox>
            <Avatar error size={120} />
            <div>{error.message}</div>
          </ErrorBox>
        )}
        {chat?.config?.brand?.active && (
          <FooterContainer>
            <a
              href={chat.config.brand.redirect_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{chat.config.brand.text}</span>
              <img src={chat.config.brand.image_url} alt="" />
            </a>
          </FooterContainer>
        )}
      </Container>
      {!loading && chat && !fullscreen && (userInteracted || !silent) && (
        <Avatar
          chatId={chatId}
          type={open ? 'collapse' : 'initial'}
          size={80}
          position={position}
          margins={margins}
          onClick={handleToggleChat}
          colorChat={chat.config?.color_chat || '#409dca'}
        />
      )}
    </Wrapper>
  );
};

export default Chat;
