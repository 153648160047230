import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const memoryStorage: Record<string, string> = {};

export function useStickyState<S>(
  defaultValue: S,
  key: string,
  storage: 'sessionStorage' | 'localStorage' | 'memoryStorage' = 'localStorage'
): [S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState<S>(() => {
    if (!key) return defaultValue;
    try {
      if (storage === 'memoryStorage') {
        const stickyValue = memoryStorage[key];
        return stickyValue ? JSON.parse(stickyValue) : defaultValue;
      }
      const stickyValue = window ? window[storage].getItem(key) : null;
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (key) {
      if (storage === 'memoryStorage') {
        memoryStorage[key] = JSON.stringify(value);
      } else if (window) {
        window[storage].setItem(key, JSON.stringify(value));
      }
    }
  }, [key, value, storage]);

  return [value, setValue];
}
