/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger */
import React, { useCallback, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { v4 as uuid } from 'uuid';

import { IFlowOptions, IFlowOptionData } from '../../store/ducks/chats/types';
import Avatar from '../Avatar';
import ImageModal from '../ImageModal';
import PulseLoader from '../Loader';
import { Container, Content } from './styles';

interface IProps {
  id: string;
  type: string;
  content?: string;
  tag?: string;
  options?: IFlowOptions;
  showAvatar: boolean;
  onOptionClick?: (id: string, opt: IFlowOptionData, tag?: string) => void;
  colorChat?: string;
}

const MessageBox: React.FC<IProps> = ({
  id,
  type,
  content,
  tag,
  options,
  showAvatar,
  colorChat,
  onOptionClick
}: IProps) => {
  const handleOptionClick = useCallback(
    (itemId: string, option: IFlowOptionData) => {
      if (onOptionClick) {
        onOptionClick(itemId, option, tag);
      }
    },
    [onOptionClick, tag]
  );

  const mappedOptions = useMemo<IFlowOptionData[]>(() => {
    if (!options || !Object.keys(options).length) return [];
    const output = Object.entries(options).map(opt => ({
      ...opt[1],
      id: uuid()
    }));
    return output;
  }, [options]);

  if (!content && type !== 'LOADING') {
    return <></>;
  }

  return (
    <Container reverse={type === 'ANSWER'}>
      {showAvatar && <Avatar type={type === 'ANSWER' ? 'user' : 'bot'} />}
      <Content colorChat={colorChat} showAvatar={showAvatar} type={type}>
        {type === 'ERROR' && <span>{content}</span>}
        {type === 'LOADING' && <PulseLoader size={8} color={colorChat} />}
        {(type === 'TEXT' ||
          type === 'ANSWER' ||
          type === 'REDIRECT' ||
          type === 'ZENDESK') && (
          <span
            dangerouslySetInnerHTML={{
              __html: content ?? ''
            }}
          />
        )}
        {type === 'IMAGE' && content && <ImageModal imageUrl={content} />}
        {type === 'VIDEO' && (
          <ReactPlayer url={content} light width={242} height={134} />
        )}
        {(type === 'QUESTION' ||
          ((type === 'OPTIONS' || type === 'DYNAMIC') && !options)) && (
          <span
            dangerouslySetInnerHTML={{
              __html: content ?? ''
            }}
          />
        )}
        {(type === 'OPTIONS' || type === 'DYNAMIC') &&
          !!mappedOptions?.length &&
          mappedOptions.map(opt => (
            <button
              key={opt.id}
              type="button"
              className="shadow-2"
              onClick={() => handleOptionClick(id, opt)}
            >
              {opt.message}
            </button>
          ))}
      </Content>
    </Container>
  );
};

export default MessageBox;
