/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable radix */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { IApplicationState as AppState } from '../store';
import { ChatActions } from '../store/ducks/chats/actions';
import { FlowItemType, ITimeline } from '../store/ducks/chats/types';

type ZendeskDepartmentKeys = {
  default: number;
};

interface ZendeskContextData {
  showChatZendesk: (chatContent: ITimeline[], attendant_name: string) => void;
  getZendeskSettings: () => void;
}

const extractTextFromHtml = (html: string): string => {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

const ZendeskContext = createContext<ZendeskContextData>(
  {} as ZendeskContextData
);

let zendeskSettings = {};

const ZendeskProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { timeline, flow, tags, zendeskInitialMessageSent } = useSelector(
    (state: AppState) => state.chats
  );

  const [chatLoaded, setChatLoaded] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [chatStarted, setChatStarted] = useState(false);
  const [zendeskChatId, setZendeskChatId] = useState('');
  const [departmentZendesk, setDepartmentZendek] = useState(0);

  const userName = useMemo(() => {
    const externalInfoNameTags = flow
      .filter(
        item =>
          (item.type === 'externalInfo' || item.type === 'question') &&
          item.data?.answerType === 'name' &&
          !!item.data?.tag
      )
      .map(item => `*|${item.data?.tag}|*`);

    if (externalInfoNameTags.length) {
      const externalName = tags.find(tag =>
        externalInfoNameTags.includes(tag.key)
      )?.value;

      if (externalName) {
        return externalName;
      }
    }

    return '';
  }, [flow, tags]);

  const userEmail = useMemo(() => {
    const externalInfoNameTags = flow
      .filter(
        item =>
          (item.type === 'externalInfo' || item.type === 'question') &&
          item.data?.answerType === 'email' &&
          !!item.data?.tag
      )
      .map(item => `*|${item.data?.tag}|*`);

    if (externalInfoNameTags.length) {
      const externalName = tags.find(tag =>
        externalInfoNameTags.includes(tag.key)
      )?.value;

      if (externalName) {
        return externalName;
      }
    }

    return '';
  }, [flow, tags]);

  const chatInit = useCallback(() => {
    setChatLoaded(true);
    ZendeskAPI('webWidget', 'hide');
  }, []);

  const buildChatHistory = useCallback(
    (chatContent: ITimeline[], attendant_name: string) => {
      const mappedText = chatContent
        .filter(item => item.type !== FlowItemType.ACTION)
        .map(item => {
          const content = extractTextFromHtml(item.content);
          const actor =
            item.type === FlowItemType.ANSWER
              ? userName || 'Resposta'
              : attendant_name;

          return `${actor}:\n${content}`;
        });

      const msg = `Histórico da conversa:\n\n${mappedText.join('\n\n')}`
      setChatMessage(msg);
      localStorage.setItem(
        'zendesk_initial_message',
        `Histórico da conversa:\n\n${mappedText.join('\n\n')}`
      );
      return msg;
    },
    [userName]
  );

  const resetZendeskChat = useCallback(() => {
    setChatMessage('');
    localStorage.setItem('zendesk_initial_message', '');
    localStorage.setItem('zendesk_chat_is_finished', 'true');
    (window as any).zE('webWidget', 'chat:end');
    console.info('[CHATBOT] Chat ended');
    dispatch(ChatActions.removeChat());
    dispatch(ChatActions.closeZendeskChat());
    dispatch(
      ChatActions.setZendeskInitialMessageAsSent({
        sent: ''
      })
    );
    ZendeskAPI('webWidget', 'hide');
    console.info('[CHATBOT] zendesk closed');
    const closeChat = new Event('closeChat');
    window.dispatchEvent(closeChat);
  }, []);

  const loadContactForm = useCallback((department?: any) => {
    console.info('[CHATBOT] Loading contact form');
    ZendeskAPI('webWidget', 'updateSettings', {
      webWidget: {
        chat: {
          departments: {
            enabled: [department?.id],
            select: department?.id
          },
          suppress: true
        },
        contactForm: {
          suppress: false
        }
      }
    });
    if (userName && userEmail) {
      ZendeskAPI('webWidget', 'prefill', {
        name: {
          value: userName,
          readOnly: true
        },
        email: {
          value: userEmail,
          readOnly: true
        }
      });
    }

    // detect when user submit an offline form
    ZendeskAPI('webWidget:on', 'userEvent', (event: any) => {
      if (event.action === 'Web Widget Minimised') {
        // close zendesk chat by inacitivy after 30 seconds
        event.action = '';
        if (!(window as any).zE('webWidget:get', 'chat:isChatting')) {
          setTimeout(resetZendeskChat, 1000 * 30);
        }
      }

      if (event.action === 'Contact Form Submitted') {
        event.action = '';
        setTimeout(() => {
          resetZendeskChat();
        }, 7000);
      }
    });

    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');
  }, []);

  const loadChat = useCallback(
    (department, initial_message: string) => {
      console.info('[CHATBOT] Load chat. Department:', department);

      const storageInitialMessage = localStorage.getItem(
        'zendesk_initial_message'
      );

      if (!department || department?.status === 'offline') {
        localStorage.setItem('zendesk_initial_message', '');
        loadContactForm(department);
        return;
      }

      (window as any).zE('webWidget:on', 'chat:start', () => {
        localStorage.setItem('zendesk_chat_is_finished', '');
      });

      ZendeskAPI('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            suppress: false,
            departments: {
              enabled: [department?.id],
              select: department?.id
            }
          },
          contactForm: {
            suppress: true
          }
        }
      });

      if (!zendeskInitialMessageSent || !storageInitialMessage?.length) {
        if (!initial_message) {
          initial_message =
            localStorage.getItem('zendesk_initial_message') || '';
        }
        (window as any).zE('webWidget', 'chat:send', initial_message);
        dispatch(
          ChatActions.setZendeskInitialMessageAsSent({
            sent: initial_message
          })
        );
        localStorage.setItem('zendesk_initial_message', initial_message);
      } else {
        setTimeout(() => {
          (window as any).zE('webWidget', 'show');
          (window as any).zE('webWidget', 'open');
        }, 500);
      }
    },
    [dispatch, loadContactForm, zendeskInitialMessageSent]
  );

  const zendeskTags = useMemo(() => {
    const zendeskAction = timeline[timeline.length - 1];
    if (
      zendeskAction?.zendeskChat &&
      !!Object.keys(zendeskAction?.zendeskChat).length
    ) {
      const zendeskCustomTags = zendeskAction?.zendeskChat?.tags.custom.map(
        tag => tag.value
      );

      const zendeskChatbotTags = zendeskAction?.zendeskChat?.tags.chatbot.map(
        tag => tag.value
      );

      const tagNames = tags.map(tag => tag.key.split('|')[1]);

      const formattedChatbotTags = zendeskChatbotTags
        .filter(chatbotTag => tagNames.includes(chatbotTag))
        .map(chatbotTag => {
          const index = tags.findIndex(tag => tag.key === `*|${chatbotTag}|*`);
          const key = tags[index].key.toLowerCase().split('|')[1];
          let value = String(tags[index].value);

          value = value.replace(/[^\w\s]/gi, '').replace(/ /g, '_');
          value = value.toLowerCase();
          return `${key}_${value}`;
        });

      return [...zendeskCustomTags, ...formattedChatbotTags];
    }
    return [];
  }, [tags, timeline]);

  const showChatZendesk = useCallback(
    (chatContent: ITimeline[], attendant_name: string) => {
      console.info(
        '[CHATBOT] ShowChatZendesk method: zE exists?',
        !!(window as any).zE,
        'zE settings exists?',
        !!(window as any).zESettings
      );

      if (!localStorage.getItem('zendesk_initial_message')?.length) {
        ZendeskAPI('webWidget', 'close');
        ZendeskAPI('webWidget', 'reset');
      }

      ZendeskAPI('webWidget', 'chat:addTags', zendeskTags);
      buildChatHistory(chatContent, attendant_name);
      ZendeskAPI('webWidget:on', 'chat:end', () => {
        resetZendeskChat();
      });

      // if (msg) {
      //   loadChat(departmentZendesk, msg);
      // }

      ZendeskAPI('webWidget', 'show');
      ZendeskAPI('webWidget', 'open');
    },
    [buildChatHistory, dispatch, zendeskTags]
  );

  const startChat = useCallback(
    (department_code, initial_message) => {
      console.info('[CHATBOT] Starting chat...');
      setChatStarted(true);

      const storageDepartment = parseInt(
        localStorage.getItem('zendesk_department') || '',
        10
      );

      if (!department_code) department_code = storageDepartment;

      const department = (window as any).zE(
        'webWidget:get',
        'chat:department',
        department_code
      );

      if (department && initial_message.length) {
        loadChat(department, initial_message);
        return;
      }

      ZendeskAPI('webWidget:on', 'chat:connected', () => {
        const chat_department = (window as any).zE(
          'webWidget:get',
          'chat:department',
          department_code
        );

        if (chat_department && initial_message.length) {
          loadChat(chat_department, initial_message);
        }
      });
    },
    [loadChat]
  );

  useEffect(() => {
    const isChatLoaded = chatLoaded || !!(window as any).zE;
    if (isChatLoaded && userName && userEmail) {
      console.info('[CHATBOT] Setting user name:', userName);
      console.info('[CHATBOT] Setting user email:', userEmail);
      ZendeskAPI('webWidget', 'identify', {
        name: userName,
        email: userEmail
      });
      ZendeskAPI('webWidget', 'prefill', {
        name: {
          value: userName,
          readOnly: true
        },
        email: {
          value: userEmail,
          readOnly: true
        }
      });
      ZendeskAPI('webWidget', 'chat:addTags', zendeskTags);
    }
  }, [chatLoaded, userEmail, userName, zendeskTags]);

  useEffect(() => {
    const item = timeline[timeline.length - 1];
    if (item && item.zendeskChat) {
      zendeskSettings = {
        contactOptions: {
          enabled: false
        },
        chat: {
          departments: {
            enabled: [item.zendeskChat.department],
            select: item.zendeskChat.department
          }
        },
        helpCenter: {
          suppress: true
        },
        position: {
          horizontal: 'right',
          vertical: 'bottom'
        },
        webWidget: {
          zIndex: 9999
        }
      };
      setZendeskChatId(item.zendeskChat.zendeskKey);
      const storageDepartment = parseInt(
        localStorage.getItem('zendesk_department') || ''
      );
      setDepartmentZendek(item.zendeskChat.department || storageDepartment);
      if (item.zendeskChat.department && !storageDepartment) {
        localStorage.setItem(
          'zendesk_department',
          `${item.zendeskChat.department}`
        );
      }
    }
  }, [timeline]);

  useEffect(() => {
    const isChatLoaded = chatLoaded || !!(window as any).zE;

    if (!departmentZendesk)
      setDepartmentZendek(
        parseInt(localStorage.getItem('zendesk_chat_is_finished') || '')
      );
    if (!isChatLoaded) return;

    if (!chatStarted && chatMessage.length) {
      startChat(departmentZendesk, chatMessage);
    }
  }, [
    chatLoaded,
    departmentZendesk,
    loadContactForm,
    chatMessage,
    startChat,
    chatStarted
  ]);

  const getZendeskSettings = useCallback(() => {
    return {
      webWidget: {
        contactOptions: {
          enabled: false
        },
        chat: {
          departments: {
            enabled: [departmentZendesk],
            select: departmentZendesk
          }
        },
        helpCenter: {
          suppress: true
        },
        position: {
          horizontal: 'right',
          vertical: 'bottom'
        },
        zIndex: 9999
      }
    };
  }, [departmentZendesk]);

  return (
    <ZendeskContext.Provider value={{ showChatZendesk, getZendeskSettings }}>
      {children}
      {zendeskChatId && (
        <Zendesk
          defer
          zendeskKey={zendeskChatId}
          {...zendeskSettings}
          onLoaded={chatInit}
        />
      )}
    </ZendeskContext.Provider>
  );
};

function useZendesk(): ZendeskContextData {
  const context = useContext(ZendeskContext);
  if (!context) {
    throw new Error('useZendesk must be used within a ZendeskProvider');
  }
  return context;
}

export { ZendeskProvider, useZendesk };
