/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { call } from 'redux-saga/effects';
import { v4 } from 'uuid';
import validator from 'validator'
import * as Yup from 'yup';
import {
  FlowItemType,
  IFlowItem,
  IFlowOptionData,
  IFlowOptions,
  ITag,
  IValidationResult
} from './types';

interface ReplaceConfigs {
  allowEmptyReplace?: boolean;
  errorOnReplaceProblem?: boolean;
  encodeValueToLink?: boolean;
}

const genericFetch = (options: AxiosRequestConfig): AxiosPromise<any> => {
  return axios(options);
};

export async function answerValidation(
  question: IFlowItem,
  answer: string
): Promise<IValidationResult> {
  const baseSchema = Yup.string().required();
  let valid = await baseSchema.isValid(answer.trim());
  if (!valid) {
    return { valid: false };
  }

  if (
    question.data?.answerType === 'email' ||
    question.data?.dynamicField?.type === 'email'
  ) {
    const emailSchema = Yup.string().email();
    valid = await emailSchema.isValid(answer);
    if (!valid) {
      return { valid: false, error: 'O e-mail informado é inválido!' };
    }
  }

  if (
    question.data?.answerType === 'phone' ||
    question.data?.dynamicField?.type === 'phone'
  ) {
   const isValidPhoneNumber= validator.isMobilePhone(answer)

   if (!isValidPhoneNumber) {
    return { valid: false, error: 'O número informado é inválido!' };
  }
}

  return { valid: true };
}

export function answerSanitize(question: IFlowItem, answer: string): string {
  if (
    question.data?.answerType === 'phone' ||
    question.data?.dynamicField?.type === 'phone'
  ) {
    return answer.replace(/[-() ]/g, '');
  }

  return answer;
}

export function clearStorage(): void {
  const localStorageKeys = Object.keys(window.localStorage);
  localStorageKeys.forEach(key => {
    if (key.startsWith('@CHATSALES')) {
      window.localStorage.removeItem(key);
    }
  });
}

export function* fetchClientIp() {
  const storedIp = localStorage.getItem('@CHATSALES:APP:CLIENT_IP');
  if (storedIp) return storedIp;

  const response: AxiosResponse<any> = yield call(genericFetch, {
    method: 'GET',
    url: 'https://www.cloudflare.com/cdn-cgi/trace'
  });

  if (response.status === 200 && response.data) {
    const data = response.data.split('\n') as string[];
    const rawClientIp = data.find(item => item.startsWith('ip='));
    if (rawClientIp) {
      const clientIp = rawClientIp.replace('ip=', '');
      localStorage.setItem('@CHATSALES:APP:CLIENT_IP', clientIp);
      return clientIp;
    }
  }

  const clientIp = v4();
  localStorage.setItem('@CHATSALES:APP:CLIENT_IP', clientIp);
  return clientIp;
}

export function getTarget(link: string, items: IFlowItem[]): IFlowItem | null {
  if (!link) return null;

  const targetId = link.replace(/plug-/g, 'plug_').split('-')[2].split('__')[0];

  return items.find(_item => _item.id === targetId) ?? null;
}

export function mapFlowItemType(flowItemType: string): FlowItemType {
  switch (flowItemType) {
    case 'action':
      return FlowItemType.ACTION;
    case 'image':
      return FlowItemType.IMAGE;
    case 'options':
      return FlowItemType.OPTIONS;
    case 'question':
      return FlowItemType.QUESTION;
    case 'video':
      return FlowItemType.VIDEO;
    default:
      return FlowItemType.TEXT;
  }
}

export function replaceTags(
  value: string,
  tags: ITag[],
  replaceConfigs: ReplaceConfigs = {
    allowEmptyReplace: false,
    errorOnReplaceProblem: false,
    encodeValueToLink: false
  }
): string | Error {
  const tagsToReplace = value.match(/(\*\|)(?:(?=(\\?))\2.)*?(\|\*)/g);
  if ((tagsToReplace?.length ?? 0) > 0) {
    try {
      const newValue = value.replace(/(\*\|)(?:(?=(\\?))\2.)*?(\|\*)/g, tag => {
        const tagValue = tags.find(x => x.key === tag);
        if (!tagValue && replaceConfigs.errorOnReplaceProblem) {
          throw new Error('Error on replace tags.');
        }
        if (tagValue && replaceConfigs.encodeValueToLink) {
          const encodedValue = encodeURI(tagValue.value);
          return encodedValue;
        }
        if (tagValue?.value) {
          return tagValue?.value;
        }
        return replaceConfigs.allowEmptyReplace ? '' : '***';
      });
      return newValue;
    } catch (err) {
      return err as Error;
    }
  }
  return value;
}

export function replaceTagsInOptions(
  options: IFlowOptions | undefined,
  tags: ITag[],
  replaceConfigs: ReplaceConfigs = {
    allowEmptyReplace: false,
    errorOnReplaceProblem: false
  }
): IFlowOptions | undefined {
  if (!options) return undefined;

  let optionsWithTagsReplaced = {} as Record<string, IFlowOptionData>;
  Object.keys(options).forEach(optionKey => {
    const optionCurrentInfos = options[optionKey];
    const message = replaceTags(
      options[optionKey].message,
      tags,
      replaceConfigs
    );
    const link = options[optionKey].link
      ? replaceTags(String(options[optionKey].link), tags, {
          ...replaceConfigs,
          encodeValueToLink: true
        })
      : undefined;
    if (message instanceof Error || link instanceof Error) {
      return;
    }
    optionsWithTagsReplaced = {
      ...optionsWithTagsReplaced,
      [optionKey]: {
        ...optionCurrentInfos,
        message,
        link
      }
    };
  });

  return optionsWithTagsReplaced;
}

export function setFieldMask(item: IFlowItem): IFlowItem {
  let mask = '';
  let defaultValue = '';

  if (item.data) {
    if (
      item.data.answerType === 'phone' ||
      item.data?.dynamicField?.type === 'phone'
    ) {
      mask = '99 (99) 99999-9999';
      defaultValue = '55';
    } else if (item.data?.dynamicField?.type === 'date') {
      mask = '99/99/9999';
    } else if (item.data?.dynamicField?.type === 'cpf') {
      mask = '999.999.999-99';
    } else if (item.data?.dynamicField?.type === 'cnpj') {
      mask = '99.999.999/9999-99';
    }
  }

  if (mask) {
    return {
      ...item,
      data: {
        ...item.data,
        mask,
        defaultValue
      }
    } as IFlowItem;
  }

  return item;
}

export function organizeExternalInfosTags(flowItens: IFlowItem[]): ITag[] {
  const tags = flowItens.reduce((acc, item) => {
    let value = '';
    if (item.data?.externalInfo?.scriptInfo) {
      value =
        window?.chat?.externalInfos?.[
          `${item.data?.externalInfo?.scriptInfo.variable}`
        ] || '';
    }
    if (item.data?.tag) {
      return [
        ...acc,
        {
          key: `*|${item.data.tag}|*`,
          value
        }
      ];
    }
    return acc;
  }, [] as ITag[]);
  return tags;
}
