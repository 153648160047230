import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { MdSend } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';

import {
  IFlowItem,
} from '../../../store/ducks/chats/types';
import CheckBoxList, { ICheckBoxListHandles } from './CheckBoxList';
import { Container } from './styles';

interface IProps {
  question: IFlowItem | null;
  onSubmit: (answer: string) => void;
  onFocus: () => void;
  colorChat: string;
}

const QuestionBox: React.FC<IProps> = ({
  question,
  onSubmit,
  onFocus,
  colorChat
}) => {
  const [answer, setAnswer] = useState('');
  const answerInput = useRef<HTMLInputElement>(null);
  const checkboxListRef = useRef<ICheckBoxListHandles>(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  const isCheckboxListField = useMemo<boolean>(() => {
    return question?.data?.dynamicField?.type === 'checkbox';
  }, [question]);

  const handleAnswerChange = useCallback(
    (value: string) => {
      setAnswer(value);
    },
    [question?.data]
  );

  const handleOnChange = (value: any, country: any) => {
    handleAnswerChange(value)
    setPhoneNumber(value);
  };

  const handleAnswerSubmit = useCallback(
    (event?: React.FormEvent<HTMLFormElement> | string) => {
      if (typeof event !== 'string' && event) {
        event.preventDefault();
      }
      let answerToSubmit = typeof event === 'string' ? event : answer;

      if (isCheckboxListField && checkboxListRef.current) {
        const selectedValues = checkboxListRef.current.getCheckedItems();
        onSubmit(selectedValues.join(', '));
      } else {
        if(question?.data?.answerType === 'phone'){
          onSubmit(`+${answerToSubmit}`);
        } else {
          onSubmit(answerToSubmit);
        }
      }
    },
    [answer, isCheckboxListField, onSubmit, question?.data?.answerType]
  );

  const handleFocus = useCallback(() => {
    onFocus();
  }, [onFocus]);

  const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAnswerSubmit();
    }
  }, [handleAnswerSubmit]);

  useEffect(() => {
    const value = question?.data?.defaultValue || '';
    setAnswer(value);
    handleAnswerChange(value);
  }, [question?.id, question?.data?.defaultValue, handleAnswerChange]);

  useEffect(() => {
    if (question && answerInput && answerInput.current) {
      answerInput.current.focus();
    }
  }, [question]);

  if (!question) return <></>;

  return (
    <Container colorChat={colorChat} onSubmit={handleAnswerSubmit}>
      {isCheckboxListField && !!question.data && (
        <CheckBoxList data={question.data} ref={checkboxListRef} />
      )}
      {question?.data?.answerType === 'phone' ? (
        <PhoneInput
          country={'br'}
          value={phoneNumber}
          onChange={handleOnChange}
          onEnterKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleAnswerSubmit(phoneNumber);
            }
          }}
          defaultErrorMessage='Telefone invalido'
          onFocus={handleFocus}
        />
      ) : (
        <input
          type="text"
          ref={answerInput}
          value={answer}
          placeholder={question.data?.placeholder}
          onChange={event => handleAnswerChange(event.target.value)}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
        />
      )}
      <button type="submit" className="shadow-2">
        <MdSend />
      </button>
    </Container>
  );
};

export default QuestionBox;
