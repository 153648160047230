import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  color: #8e8e8e;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 22px;
  cursor: default;
`;

export const List = styled.div`
  padding-top: 8px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 22px;

  input {
    max-width: 13px;
    margin-right: 8px;
  }

  label {
    font-size: 14px;
    line-height: 16px;
  }
`;
