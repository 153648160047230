import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { createReducer } from 'reduxsauce';
import {
  ChatTypes,
  ErrorType,
  IAddAnswerSuccessAction,
  IAddQuestionRequestAction,
  IAddTimelineItemSuccessAction,
  IChatState,
  ILoadChatSuccessAction,
  ILoadExternalInfoSuccessAction,
  ISetAwaitToRenderAction,
  ISetChatAsRemovedAction,
  ISetErrorAction,
  ISetUserInteractedAction,
  ISetZendeskChatAsOpenAction,
  ISetZendeskInitialMessageAsSentAction
} from './types';

const INITIAL_STATE: IChatState = {
  id: '',
  name: '',
  active: true,
  config: null,
  error: null,
  loading: false,
  question: null,
  nextItem: null,
  flow: [],
  tags: [],
  timeline: [],
  finished: false,
  removed: false,
  zendeskChatOpen: false,
  zendeskInitialMessageSent: '',
  awaitToRender: true,
  userInteracted: false
};

const addAnswerSuccess: Reducer = (
  state = INITIAL_STATE,
  action: IAddAnswerSuccessAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    if (action.payload) {
      draft.timeline = action.payload.timelineItem
        ? [
            ...draft.timeline.map(t => ({
              ...t,
              last: false
            })),
            action.payload.timelineItem
          ]
        : draft.timeline;
      draft.nextItem = action.payload?.nextItem ?? '';
      draft.question = null;
      if (action.payload.tag && !Array.isArray(action.payload.tag)) {
        const tagIndex = draft.tags.findIndex(
          tag => tag.key === action.payload?.tag?.key
        );
        if (tagIndex >= 0) {
          draft.tags[tagIndex] = action.payload.tag;
        } else {
          draft.tags.push(action.payload.tag);
        }
      }
    }
  });

const addQuestionRequest: Reducer = (
  state = INITIAL_STATE,
  action: IAddQuestionRequestAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.question = action.payload?.question ?? draft.question;
  });

const addTimelineItemRequest: Reducer = (state = INITIAL_STATE) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.loading = true;
  });

const addTimelineItemSuccess: Reducer = (
  state = INITIAL_STATE,
  action: IAddTimelineItemSuccessAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.loading = false;
    draft.nextItem = action.payload?.nextItem ?? '';
    draft.timeline = action.payload
      ? [
          ...draft.timeline.map(t => ({
            ...t,
            last: false
          })),
          action.payload.timelineItem
        ]
      : draft.timeline;
  });

const clearChat: Reducer = () => INITIAL_STATE;

const clearStorage: Reducer = (state = INITIAL_STATE) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.question = null;
    draft.tags = [];
    draft.timeline = [];
  });

const loadChatSuccess: Reducer = (
  state = INITIAL_STATE,
  action: ILoadChatSuccessAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.id = action.payload?.id ?? draft.id;
    draft.name = action.payload?.name ?? draft.name;
    draft.flow = action.payload?.flow ?? draft.flow;
    draft.active = action.payload?.active ?? draft.active;
    draft.config = action.payload?.config ?? draft.config;
  });

const loadExternalInfoSuccess: Reducer = (
  state = INITIAL_STATE,
  action: ILoadExternalInfoSuccessAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    if (action.payload) {
      if (action.payload?.tags?.length) {
        action.payload.tags.forEach(tag => {
          const tagIndex = draft.tags.findIndex(item => item.key === tag.key);
          if (tagIndex >= 0) {
            draft.tags[tagIndex] = tag;
          } else {
            draft.tags.push(tag);
          }
        });
      }
    }
  });

const setAwaitToRender: Reducer = (
  state = INITIAL_STATE,
  action: ISetAwaitToRenderAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.awaitToRender = action.payload?.awaitToRender ?? draft.awaitToRender;
  });

const setChatAsRemoved: Reducer = (
  state = INITIAL_STATE,
  action: ISetChatAsRemovedAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.removed = action.payload?.removed ? new Date() : false;
  });

const setChatAsFinished: Reducer = (state = INITIAL_STATE) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.finished = true;
  });

const setError: Reducer = (state = INITIAL_STATE, action: ISetErrorAction) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.error =
      action.payload && action.payload.error
        ? {
            message: action.payload?.error ?? '',
            type: action.payload?.type ?? ErrorType.BadRequest
          }
        : null;
  });

const setUserInteracted: Reducer = (state = INITIAL_STATE) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.userInteracted = true;
  });

const setZendeskChatAsOpen: Reducer = (
  state = INITIAL_STATE,
  action: ISetZendeskChatAsOpenAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.zendeskChatOpen = !!action.payload?.open;
  });

const setZendeskInitialMessageAsSent: Reducer = (
  state = INITIAL_STATE,
  action: ISetZendeskInitialMessageAsSentAction
) =>
  produce(state, (draft: Draft<IChatState>) => {
    draft.zendeskInitialMessageSent = action.payload?.sent ?? '';
  });

export default createReducer(INITIAL_STATE, {
  [ChatTypes.ADD_ANSWER_SUCCESS]: addAnswerSuccess,
  [ChatTypes.ADD_QUESTION_REQUEST]: addQuestionRequest,
  [ChatTypes.ADD_TIMELINE_ITEM_REQUEST]: addTimelineItemRequest,
  [ChatTypes.ADD_TIMELINE_ITEM_SUCCESS]: addTimelineItemSuccess,
  [ChatTypes.LOAD_CHAT_SUCCESS]: loadChatSuccess,
  [ChatTypes.LOAD_EXTERNAL_INFO_SUCCESS]: loadExternalInfoSuccess,
  [ChatTypes.CLEAR_CHAT]: clearChat,
  [ChatTypes.CLEAR_STORAGE]: clearStorage,
  [ChatTypes.SET_AWAIT_TO_RENDER]: setAwaitToRender,
  [ChatTypes.SET_CHAT_AS_FINISHED]: setChatAsFinished,
  [ChatTypes.SET_CHAT_AS_REMOVED]: setChatAsRemoved,
  [ChatTypes.SET_ERROR]: setError,
  [ChatTypes.SET_USER_INTERACTED]: setUserInteracted,
  [ChatTypes.SET_ZENDESK_CHAT_AS_OPEN]: setZendeskChatAsOpen,
  [ChatTypes.SET_ZENDESK_INITIAL_MESSAGE_AS_SENT]: setZendeskInitialMessageAsSent
});
