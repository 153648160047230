import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const chatDiv = document.getElementById('chatsales-widget');
// const chatId =
//   chatDiv?.dataset.chatId ?? process.env.REACT_APP_DEBUG_CHATID ?? '';

// Sentry.init({
//   dsn:
//     'https://cccbb571dd4e4df992158fbeda1937e0@o522096.ingest.sentry.io/5633054',
//   integrations: [
//     new Integrations.BrowserTracing(),
//     new CaptureConsole({
//       levels: ['error']
//     })
//   ],
//   beforeSend: event => {
//     if (chatId === 'ACFA3AFE-12C5-459D-B936-502A3588C90D') {
//       return event;
//     }
//     return null;
//   },

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// });

ReactDOM.render(<App />, chatDiv);
