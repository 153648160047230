import { darken, shade, tint } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  reverse: boolean;
}

interface IContentProps {
  showAvatar: boolean;
  type: string;
  colorChat?: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex: 0 0 auto;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  align-items: flex-start;
  justify-content: ${props => (props.reverse ? 'right' : 'left')};
  margin: ${props => (props.reverse ? '10px 0 5px' : '5px 0 0 0')};
`;

export const Content = styled.div<IContentProps>`
  background: #f4f5f8;
  border-radius: 1.2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: ${props =>
    props.showAvatar || props.type === 'ERROR' ? '0 5px' : '0 45px'};
  padding: ${props => (props.type === 'IMAGE' ? '10px' : '13px 15px')};

  ${({ type }) =>
    type === 'ERROR' &&
    `
    background: tomato;
    border-radius: 5px;
    padding: 7px 12px;
  `}

  ${({ type, colorChat }) =>
    type === 'ANSWER' &&
    css`
      background: ${tint(0.8, colorChat || '#b4e4fb')};
      justify-self: flex-end;
      margin: 0 5px 0 80px;
    `}

  ${({ type }) =>
    type !== 'ANSWER' &&
    type !== 'ERROR' &&
    css`
      margin-right: 30px;
    `}

  span {
    color: ${props => (props.type === 'ERROR' ? '#fff' : '#6f727d')};
    cursor: default;
  }

  button {
    border-radius: 5px;
    border: none;
    background-color: ${props => props.colorChat || '#409dca'};
    color: #fff;
    margin: 4px;
    padding: 6px 12px;

    &:hover {
      background: ${props => darken(0.08, props.colorChat || '#409dca')};
    }
  }
`;
