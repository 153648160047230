/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import useSWR, { responseInterface } from 'swr';

import api from '../services/api';

export interface ICustomError extends Error {
  status: number;
  statusText: string;
  message: string;
}

function isCustomError(
  err: Error | ICustomError | AxiosError
): err is ICustomError {
  return (err as ICustomError).status !== undefined;
}

export function useFetch<Data = any>(
  path: string
): responseInterface<Data, ICustomError> {
  const swrResponse = useSWR<Data, ICustomError>(
    path,
    async url => {
      try {
        const response = await api.get<Data | ICustomError>(url);
        if (response.status !== 200) {
          let customError = response.data as ICustomError;
          if (isCustomError(customError)) {
            customError.statusText = customError.status.toString();
            customError.status = response.status;
            throw customError;
          }

          customError = {
            status: response.status,
            statusText: response.statusText,
            name: response.statusText,
            message: 'Undefined error'
          } as ICustomError;

          throw customError;
        }
        return response.data as Data;
      } catch (err) {
        if (!isCustomError(err)) {
          const axiosError = err as AxiosError;
          const customError: ICustomError = {
            name: axiosError.name,
            status: axiosError.response?.status ?? 500,
            statusText:
              axiosError.response?.statusText ?? 'Internal Server Error',
            message: axiosError.message
          };

          throw customError;
        }
        throw err;
      }
    },
    {
      shouldRetryOnError: true,
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        if (retryCount ?? 0 >= 10) return;
        if (error.status === 404) return;

        setTimeout(() => {
          revalidate({ retryCount: (retryCount ?? 0) + 1 });
        }, 5000);
      }
    }
  );

  return swrResponse;
}
