import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div[type='zendeskChat'] span {
    font-style: italic;
    font-size: 12px;

    a {
      font-size: 12px;
      text-decoration: underline;
      word-break: break-all;
    }
  }
`;
