import { Reducer } from 'redux';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IChatState } from './ducks/chats/types';

export default (reducers: Reducer) => {
  // eslint-disable-next-line no-console
  const chatDiv = document.getElementById('chatsales-widget');
  const chatId =
    chatDiv?.dataset.chatId ?? process.env.REACT_APP_DEBUG_CHATID ?? '';
  const transformPersistedState = createTransform<IChatState, IChatState>(
    null,
    (outboundState, key) => {
      switch (key) {
        case 'chats':
          return {
            ...outboundState,
            awaitToRender: true
          };
        default:
          return outboundState;
      }
    }
  );
  const persistedReducer = persistReducer(
    {
      key: `APP:${chatId}`,
      keyPrefix: '@CHATSALES:',
      storage,
      whitelist: ['chats'],
      transforms: [transformPersistedState]
    },
    reducers
  );
  return persistedReducer;
};
