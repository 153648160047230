import React, { useState, useEffect, useMemo } from 'react';
import { IRedirectTo } from '../../../store/ducks/chats/types';
import MessageBox from '../../MessageBox';

import { Container } from './styles';

interface IProps {
  id: string;
  redirect: IRedirectTo;
  last: boolean;
  finished: boolean;
}

const RedirectBox: React.FC<IProps> = ({ id, redirect, last, finished }) => {
  const [secondsToRedirect, setSecondsToRedirect] = useState(5);

  const startCountToRedirect = () => {
    if (secondsToRedirect > 0) {
      if (finished) {
        setSecondsToRedirect(-1);
      } else {
        setSecondsToRedirect(oldSeconds => {
          if (oldSeconds > 1) {
            setTimeout(startCountToRedirect, 1000);
          } else {
            window.open(redirect.url, redirect.openNewTab ? '_blank' : '_self');
          }
          return oldSeconds - 1;
        });
      }
    }
  };

  useEffect(() => {
    startCountToRedirect();
  }, []);

  const msgRedirect = useMemo(() => {
    if (secondsToRedirect > 0) {
      return `Você será redirecionado para "<a href=${redirect.url} target="${
        redirect.openNewTab ? '_blank' : '_self'
      }">${redirect.url}</a>" em ${secondsToRedirect} seg.`;
    }
    return `Redirecionado para "<a href=${redirect.url} target="${
      redirect.openNewTab ? '_blank' : '_self'
    }">${redirect.url}</a>".`;
  }, [secondsToRedirect, redirect]);

  return (
    <Container>
      <MessageBox
        id={id}
        type="REDIRECT"
        content={msgRedirect}
        showAvatar={last}
      />
    </Container>
  );
};

export default RedirectBox;
