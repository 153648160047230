import { Store } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import createStore from './create';
import { IChatState } from './ducks/chats/types';
import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';
import persistReducers from './persist';

export interface IApplicationState {
  chats: IChatState;
}

const sagaMiddleware = createSagaMiddleware();
const store: Store<IApplicationState> = createStore(
  persistReducers(rootReducer),
  [sagaMiddleware]
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
