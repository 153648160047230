import React, { createContext, useContext, useMemo, useState } from 'react';

import {
  IChatConfig,
  IChatConfigAvatarProps
} from '../store/ducks/chats/types';

interface AvatarContextProps {
  config?: IChatConfig;
  defaultBot: IChatConfigAvatarProps;
  defaultUser: IChatConfigAvatarProps;
  setConfig: React.Dispatch<React.SetStateAction<IChatConfig | undefined>>;
}

const AvatarContext = createContext<AvatarContextProps>(
  {} as AvatarContextProps
);

export const AvatarProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState<IChatConfig>();

  const defaultBot = useMemo<IChatConfigAvatarProps>(() => {
    return {
      style: 'Circle',
      top: 'ShortHairShortCurly',
      accessories: 'Blank',
      hat_color: 'Black',
      hair_color: 'Black',
      facial_hair: 'BeardLight',
      facial_hair_color: 'BrownDark',
      clothes: 'BlazerSweater',
      clothe_color: 'Black',
      graphic_type: 'Bat',
      eye_type: 'Default',
      eyebrow_type: 'DefaultNatural',
      mouth_type: 'Default',
      skin_color: 'Light'
    };
  }, []);

  const defaultUser = useMemo<IChatConfigAvatarProps>(() => {
    return {
      style: 'Circle',
      top: 'ShortHairShortCurly',
      accessories: 'Round',
      hat_color: 'Black',
      hair_color: 'Auburn',
      facial_hair: 'Blank',
      facial_hair_color: 'Black',
      clothes: 'Hoodie',
      clothe_color: 'Red',
      graphic_type: 'Bat',
      eye_type: 'Default',
      eyebrow_type: 'UpDown',
      mouth_type: 'Default',
      skin_color: 'Pale'
    };
  }, []);

  return (
    <AvatarContext.Provider
      value={{ config, defaultBot, defaultUser, setConfig }}
    >
      {children}
    </AvatarContext.Provider>
  );
};

export const useAvatar = (): AvatarContextProps => {
  const context = useContext(AvatarContext);
  if (!context) {
    throw new Error('Hook useAvatar must be used within a AvatarProvider');
  }

  return context;
};
