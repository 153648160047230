import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IWrapperProps {
  position: 'top left' | 'top right' | 'bottom left' | 'bottom right';
  margins: {
    x: number;
    y: number;
  };
  fullscreen: boolean;
}

interface IContainerProps {
  open?: boolean;
}

interface IContainerSize {
  height: string;
  width: string;
}

interface IHeaderContentProps {
  active: boolean;
}

interface IHeaderProps {
  colorChat: string;
}

interface ICloseButtonProps {
  colorChat: string;
}

export const CloseButton = styled.button<ICloseButtonProps>`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  background: ${props => props.colorChat || '#409dca'};
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${props => darken(0.08, props.colorChat || '#409dca')};
  }
`;

const ContainerSize: IContainerSize = {
  height: '520px',
  width: window.innerWidth <= 380 ? 'calc(100vw - 32px)' : '380px'
};

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 3px;

  overflow: hidden;
  position: absolute;
  z-index: 500;

  height: ${ContainerSize.height};
  max-height: calc(100vh - 16px - 80px - 36px);

  width: ${ContainerSize.width};
  min-width: ${ContainerSize.width};
  max-width: ${ContainerSize.width};

  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.6s ease-in-out;

  ${props =>
    props.open &&
    css`
      opacity: 1;
      transform: scale(1, 1);
    `}
`;

export const ErrorBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 10px;
  overflow: hidden;

  div {
    color: tomato;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0;
  }
`;

export const Header = styled.div<IHeaderProps>`
  background: ${props => props.colorChat};
  color: #fff;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 8px;
  margin-bottom: 7px;
`;

export const HeaderContent = styled.div<IHeaderContentProps>`
  flex: 1;
  padding: 0 16px;

  h1 {
    padding-bottom: 2px;
    margin: 0;
    font-size: 18px !important;
    font-weight: bold !important;
    color: #fff !important;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.status {
      width: 8px;
      height: 8px;
      background: ${props => (props.active ? 'yellowgreen' : '#c1c1c1')};
      border-radius: 50%;
      margin-right: 8px;
    }
  }
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const QuestionBox = styled.form`
  display: flex;
  flex-direction: column;
`;

export const QuestionInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
  height: 50px;
  background-color: #f4f5f8;
  border: 1px solid #eee;
  border-radius: 8px;
  margin: 0 8px 8px 8px;

  input {
    background: transparent;
    border: 0;
    color: #333;
    flex: 1;
    font-size: 16px;
    padding: 10px 12px;

    &::placeholder {
      color: #8e8e8e;
    }
  }

  button {
    background: #409dca;
    border: none;
    height: 30px;
    width: 30px;
    margin: 0 !important;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;

    &:hover {
      background: ${darken(0.08, '#409dca')};
    }
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background: #f4f5f8;
  border-top: solid 1px #e0e3ea;
  margin-top: 4px;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 8px;

    span {
      color: #6f727d;
      margin-right: 8px;
      font-size: 12px;
    }

    img {
      max-height: 20px;
      max-width: 200px;
    }
  }
`;

const avatarBoxSize = 80;

export const Wrapper = styled.div<IWrapperProps>`
  position: fixed;
  z-index: 2147483646;
  display: flex;
  pointer-events: all;

  ${props =>
    props.position === 'bottom left' &&
    css`
      top: auto;
      right: auto;
      bottom: ${props.margins.y}px;
      left: ${props.margins.x}px;

      ${Container} {
        bottom: ${avatarBoxSize + 12}px;
        left: 0;
      }
    `}

  ${props =>
    props.position === 'bottom right' &&
    css`
      top: auto;
      right: ${props.margins.x}px;
      bottom: ${props.margins.y}px;
      left: auto;

      ${Container} {
        bottom: ${avatarBoxSize + 12}px;
        right: 0;
      }
    `}

  ${props =>
    props.position === 'top left' &&
    css`
      top: ${props.margins.y}px;
      right: auto;
      bottom: auto;
      left: ${props.margins.x}px;

      ${Container} {
        top: ${avatarBoxSize + 12}px;
        left: 0;
      }
    `}

  ${props =>
    props.position === 'top right' &&
    css`
      top: ${props.margins.y}px;
      right: ${props.margins.x}px;
      bottom: auto;
      left: auto;

      ${Container} {
        top: ${avatarBoxSize + 12}px;
        right: 0;
      }
    `}



  ${Container} {
    ${props => css`
      transform-origin: ${props.position};
      max-height: calc(100vh - ${props.margins.y}px - 80px - 36px);
    `}
  }

  ${props =>
    props.fullscreen &&
    css`
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      ${Container} {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
      }
    `}
`;

export const FinishedMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 6px;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    color: rgba(0, 0, 0, 0.16);
    border-top: dashed 1px;
    margin: 0 0 24px;
  }

  span {
    color: rgba(0, 0, 0, 0.46);
    font-style: italic;
    font-size: 13px;
    margin-bottom: 24px;
  }

  .buttons-row {
    display: flex;

    button {
      background: none;
      border: none;
      margin: 0 8px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 11px;
      line-height: 12px;
      color: rgba(0, 0, 0, 0.72);
      min-width: 80px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 4px;
      padding: 0 12px;

      &:hover {
        background: rgba(0, 0, 0, 0.08);
      }

      svg {
        margin-right: 8px;
        width: 18px;
        height: 18px;
        opacity: 0.7;
      }
    }

    hr {
      height: 100%;
      width: 1px;
      background: rgba(0, 0, 0, 0.16);
      margin: 0 16px;
      display: flex;
      border: none;
    }
  }
`;
