import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import api from '../../../services/api';
import { ChatActions } from './actions';
import {
  ChatTypes,
  ErrorType,
  FlowItemType,
  IAction,
  IChat,
  IChatState,
  ITag,
  ITimeline
} from './types';
import {
  answerSanitize,
  answerValidation,
  fetchClientIp,
  getTarget,
  mapFlowItemType,
  organizeExternalInfosTags,
  replaceTags,
  replaceTagsInOptions,
  setFieldMask
} from './utils';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

const nonBlockingTypes = ['ACTION', 'ANSWER', 'IMAGE', 'TEXT', 'VIDEO'];

export const getChat = ({ chats }: { chats: IChatState }): IChatState => ({
  id: chats.id,
  name: chats.name,
  active: chats.active,
  config: chats.config,
  flow: chats.flow,
  nextItem: chats.nextItem,
  question: chats.question,
  tags: chats.tags,
  timeline: chats.timeline,
  error: chats.error,
  loading: chats.loading,
  finished: chats.finished,
  removed: chats.removed,
  awaitToRender: chats.awaitToRender,
  zendeskChatOpen: chats.zendeskChatOpen,
  zendeskInitialMessageSent: chats.zendeskInitialMessageSent,
  userInteracted: chats.userInteracted
});
export const getTags = ({ chats }: { chats: IChatState }): ITag[] => chats.tags;
export const getTimeline = ({ chats }: { chats: IChatState }): ITimeline[] =>
  chats.timeline;

export function* addOptionAnswer(
  action: ReturnType<typeof ChatActions.addOptionAnswer>
) {
  if (action && action.payload) {
    const { id, option, tag } = action.payload;
    const timeline: ITimeline[] = yield select(getTimeline);
    if (timeline) {
      const newId = `${id}_answer`;
      const newTag: ITag | undefined = tag
        ? {
            key: `*|${tag}|*`,
            value: option?.message ?? ''
          }
        : undefined;
      yield put(
        ChatActions.addAnswerSuccess({
          timelineItem: {
            id: newId,
            type: FlowItemType.ANSWER,
            content: option?.message.toLowerCase() ?? '',
            last: true
          },
          nextItem: option?.sourceConnectionId ?? '',
          tag: newTag
        })
      );
      yield put(ChatActions.fetchNextScriptItem());
    }
  }
}

export function* addQuestionAnswer(
  action: ReturnType<typeof ChatActions.addQuestionAnswer>
) {
  if (action && action.payload) {
    const { answer } = action.payload;
    const { question } = (yield select(getChat)) as IChatState;
    if (question && question.data) {
      const newId = `${question.id ?? ''}_answer`;
      const { valid, error } = yield answerValidation(question, answer);
      if (!valid) {
        if (error)
          yield put(
            ChatActions.setError({ error, type: ErrorType.BadRequest })
          );
      } else {
        const sanitizedAnswer = answerSanitize(question, answer);
        const tag: ITag | undefined = question.data.tag
          ? {
              key: `*|${question.data.tag}|*`,
              value: sanitizedAnswer
            }
          : undefined;
        yield put(
          ChatActions.addAnswerSuccess({
            timelineItem: {
              id: newId,
              type: FlowItemType.ANSWER,
              content: answer,
              last: true
            },
            nextItem: question.data.sourceConnectionId ?? '',
            tag
          })
        );
        yield put(ChatActions.fetchNextScriptItem());
        yield put(ChatActions.setError({ error: null }));
      }
    }
  }
}

export function* addTimelineItemRequest(
  action: ReturnType<typeof ChatActions.addTimelineItemRequest>
) {
  if (action && action.payload) {
    const { flowItem } = action.payload;
    if (flowItem) {
      const { config } = (yield select(getChat)) as IChatState;
      let tags = (yield select(getTags)) as ITag[];
      tags = [
        ...tags,
        {
          key: '*|NOME_ATENDENTE|*',
          value: config?.attendant_name ?? ''
        }
      ];

      let content = '';
      if (flowItem.type === 'image') {
        content = flowItem.data?.image ?? '';
      } else if (flowItem.type === 'video') {
        content = flowItem.data?.video ?? '';
      } else if (flowItem.type === 'action') {
        content = `action_${flowItem.id}`;
      } else {
        const formattedContent =
          typeof flowItem.data?.message === 'string'
            ? replaceTags(flowItem.data?.message ?? '', tags)
            : `item_${flowItem.id}`;

        content = typeof formattedContent === 'string' ? formattedContent : '';
      }

      let redirect;
      let zendeskChat;
      let createScript;
      if (flowItem.data?.action && 'redirect' in flowItem.data.action) {
        redirect = flowItem.data.action.redirect;
      }

      if (flowItem.data?.action && 'zendeskData' in flowItem.data.action) {
        zendeskChat = flowItem.data.action.zendeskData;
      }
      if (flowItem.data?.action && 'script' in flowItem.data.action) {
        createScript = flowItem.data.action.script;
      }

      const timelineItem: ITimeline = {
        id: flowItem.id,
        type: mapFlowItemType(flowItem.type),
        dynamicType: flowItem.data?.dynamicField?.type,
        tag: flowItem.data?.tag,
        options: flowItem.data?.options,
        last: true,
        content,
        redirect,
        zendeskChat,
        createScript
      };

      if (timelineItem.type !== FlowItemType.OPTIONS) {
        timelineItem.options = undefined;
      }

      yield delay(
        flowItem.data?.delay === undefined ? 1500 : Number(flowItem.data?.delay)
      );

      if (timelineItem.type === FlowItemType.OPTIONS) {
        timelineItem.options = replaceTagsInOptions(timelineItem.options, tags);
        const opt: ITimeline = {
          ...timelineItem,
          id: `${timelineItem.id}_msg`,
          options: undefined
        };
        yield put(
          ChatActions.addTimelineItemSuccess({
            timelineItem: opt,
            nextItem: flowItem.data?.sourceConnectionId ?? ''
          })
        );
      }

      if (timelineItem.type === FlowItemType.QUESTION && flowItem.data) {
        yield put(
          ChatActions.addQuestionRequest({
            question: setFieldMask(flowItem)
          })
        );
      }

      yield put(
        ChatActions.addTimelineItemSuccess({
          timelineItem,
          nextItem: flowItem.data?.sourceConnectionId ?? ''
        })
      );
    }
  }
}

export function* addTimelineItemSuccess(
  action: ReturnType<typeof ChatActions.addTimelineItemSuccess>
) {
  if (action && action.payload) {
    const { timelineItem: last } = action.payload;
    if (last && nonBlockingTypes.includes(last.type)) {
      yield put(ChatActions.fetchNextScriptItem());
    }
    yield put(ChatActions.saveInteraction());
  }
}

export function* closeZendeskChat() {
  try {
    yield put(ChatActions.setZendeskChatAsOpen({ open: false }));
  } catch (err) {
    console.error(err);
  }
}

export function* executeAction(
  action: ReturnType<typeof ChatActions.executeAction>
) {
  try {
    if (action.payload) {
      const { action: act } = action.payload;
      if (!act.action) return;
      if ('url' in act.action) {
        const { id, tags } = yield select(getChat);
        const config = {
          ...{
            ...act.action,
            machine: act.action?.machine ?? 0,
            funnel: act.action?.funnel ?? 0,
            level: act.action?.level ?? 1,
            score: act.action?.score ?? 0,
            tags: act.action?.tags ?? []
          },
          id: uuid(),
          data: act.action?.data?.map(_data => ({
            tag: _data.tag,
            prop: _data.prop
          }))
        } as IAction;

        yield call(api.post, `chats/${id}/actions`, {
          config,
          data: tags
        });
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export function* fetchNextScriptItem() {
  const { flow, nextItem, active }: ReturnType<typeof getChat> = yield select(
    getChat
  );
  if ((flow?.length ?? 0) > 0 && active) {
    const next =
      nextItem !== null
        ? getTarget(nextItem, flow)
        : flow[flow.findIndex(item => item.data?.isInitialMessage) ?? 0];

    if (next) {
      if (next.type === 'action' && next.data) {
        yield put(ChatActions.executeAction({ action: next.data }));
      }
      yield put(ChatActions.addTimelineItemRequest({ flowItem: next }));
    } else {
      yield put(ChatActions.setChatAsFinished());
    }
  }
}

export function* loadExternalInfoRequest(
  action: ReturnType<typeof ChatActions.loadExternalInfoRequest>
) {
  if (action.payload) {
    const { flow } = action.payload;
    const externalInfoItens = flow.filter(item => item.type === 'externalInfo');
    const externalInfoTags = organizeExternalInfosTags(externalInfoItens);
    yield put(
      ChatActions.loadExternalInfoSuccess({
        tags: externalInfoTags
      })
    );
  }
}

export function* loadChatRequest(
  action: ReturnType<typeof ChatActions.loadChatRequest>
) {
  try {
    if (action.payload) {
      const { chat } = action.payload;
      const chatStored = (yield select(getChat)) as IChatState;
      if (
        chatStored &&
        (chatStored.id !== chat.id || chatStored.error || !chatStored.flow)
      ) {
        yield put(ChatActions.clearStorage());
      }
      yield put(ChatActions.setError({ error: null }));
      yield put(ChatActions.loadExternalInfoRequest({ flow: chat.flow }));
      yield put(
        ChatActions.loadChatSuccess({
          id: chat.id,
          name: chat.name,
          active: chat.active,
          config: chat.config,
          flow: chat.flow
        })
      );
    }
  } catch (err) {
    yield put(ChatActions.loadChatFailure());
    yield put(ChatActions.clearStorage());
    yield put(
      ChatActions.setError({
        error: 'Ocorreu um erro ao carregar o chat!',
        type: ErrorType.InternalServerError
      })
    );
  }
}

export function* loadChatSuccess(
  action: ReturnType<typeof ChatActions.loadChatSuccess>
) {
  let { finished } = (yield select(getChat)) as IChatState;
  const timeline = (yield select(getTimeline)) as ITimeline[];
  const last = (timeline?.length ?? 0) > 0 ? timeline.slice(-1)[0] : null;
  if (finished && !timeline.length && action.payload) {
    const chat = action.payload as IChat;
    yield put(ChatActions.restartChat({ chat }));
    finished = false;
  }
  if (!finished && (!last || nonBlockingTypes.includes(last.type))) {
    yield put(ChatActions.fetchNextScriptItem());
  }
}

export function* openZendeskChat() {
  try {
    yield put(ChatActions.setZendeskChatAsOpen({ open: true }));
  } catch (err) {
    console.error(err);
  }
}

export function* removeChat() {
  try {
    yield put(ChatActions.setChatAsRemoved({ removed: true }));
  } catch (err) {
    console.error(err);
  }
}

export function* renderChat(action: ReturnType<typeof ChatActions.renderChat>) {
  const { render_delay } = action.payload;
  try {
    yield put(ChatActions.setAwaitToRender({ awaitToRender: true }));
    yield delay(render_delay * 1000);
    yield put(ChatActions.setAwaitToRender({ awaitToRender: false }));
  } catch (err) {
    console.error(err);
  }
}

export function* restartChat(
  action: ReturnType<typeof ChatActions.restartChat>
) {
  try {
    const chat = action.payload?.chat;
    if (chat) {
      yield put(ChatActions.clearStorage());
      yield put(ChatActions.clearChat());
      if (chat.config?.render_delay) {
        yield put(ChatActions.setAwaitToRender({ awaitToRender: true }));
        yield delay(chat.config?.render_delay * 1000);
      }
      yield put(ChatActions.setAwaitToRender({ awaitToRender: false }));
      yield put(ChatActions.loadChatRequest({ chat }));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* saveInteraction() {
  try {
    const { id, tags, timeline } = (yield select(getChat)) as IChatState;
    const client_id = (yield call(fetchClientIp)) as unknown;
    yield call(api.post, `chats/${id}/interactions`, {
      client_id,
      tags,
      timeline: timeline
        .filter(item => item.type !== 'OPTIONS' || item.options?.length)
        .map(item => ({
          id: item.id,
          type: item.type,
          content: item.content
        }))
    });
  } catch (err) {
    console.error(err);
  }
}

export default all([
  takeLatest(ChatTypes.ADD_OPTION_ANSWER, addOptionAnswer),
  takeLatest(ChatTypes.ADD_QUESTION_ANSWER, addQuestionAnswer),
  takeLatest(ChatTypes.ADD_TIMELINE_ITEM_REQUEST, addTimelineItemRequest),
  takeLatest(ChatTypes.ADD_TIMELINE_ITEM_SUCCESS, addTimelineItemSuccess),
  takeLatest(ChatTypes.CLOSE_ZENDESK_CHAT, closeZendeskChat),
  takeLatest(ChatTypes.EXECUTE_ACTION, executeAction),
  takeLatest(ChatTypes.FETCH_NEXT_SCRIPT_ITEM, fetchNextScriptItem),
  takeLatest(ChatTypes.LOAD_CHAT_REQUEST, loadChatRequest),
  takeLatest(ChatTypes.LOAD_CHAT_SUCCESS, loadChatSuccess),
  takeLatest(ChatTypes.LOAD_EXTERNAL_INFO_REQUEST, loadExternalInfoRequest),
  takeLatest(ChatTypes.OPEN_ZENDESK_CHAT, openZendeskChat),
  takeLatest(ChatTypes.REMOVE_CHAT, removeChat),
  takeLatest(ChatTypes.RENDER_CHAT, renderChat),
  takeLatest(ChatTypes.RESTART_CHAT, restartChat),
  takeLatest(ChatTypes.SAVE_INTERACTION, saveInteraction)
]);
