import React from 'react';
import ReactDOM from 'react-dom';
import {
  modalRootId,
  ModalRootStyle,
  Container,
  ContentWrapper,
  Footer,
  ConfirmButtonWrapper
} from './styles';

export interface ConfirmModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  onSecondary?: () => void;
  message: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  secondaryText?: string;
  confirmColor?: string;
}

export const ModalRoot: React.FC = () => {
  return <div id={modalRootId} />;
};

export const PortalModal: React.FC = ({ children }) => {
  const modalRootEl = document
    .querySelector('.app-shadow')
    ?.shadowRoot?.getElementById(modalRootId);
  if (!modalRootEl) {
    return <></>;
  }
  return ReactDOM.createPortal(
    <>
      {children}
      <ModalRootStyle />
    </>,
    modalRootEl
  );
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  onCancel,
  onConfirm,
  onSecondary,
  message,
  confirmText,
  cancelText,
  secondaryText,
  confirmColor
}) => {
  const handleCancel = React.useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirm = React.useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const handleSecondaryAction = React.useCallback(() => {
    if (onSecondary) onSecondary();
  }, [onSecondary]);

  return (
    <Container>
      <ContentWrapper>{message}</ContentWrapper>
      <Footer>
        {onSecondary && (
          <button
            type="button"
            onClick={handleSecondaryAction}
            className="secondary-button"
          >
            {secondaryText}
          </button>
        )}
        <button type="button" onClick={handleCancel} className="cancel-button">
          {cancelText || 'Cancelar'}
        </button>
        <ConfirmButtonWrapper color={confirmColor}>
          <button
            onClick={handleConfirm}
            type="button"
            className="confirm-button"
          >
            {confirmText || 'Confirmar'}
          </button>
        </ConfirmButtonWrapper>
      </Footer>
    </Container>
  );
};

export default ConfirmModal;
