import Avataaar from 'avataaars';
import { mix } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { useAvatar } from '../../hooks/avatar';
import useDebounce from '../../hooks/useDebounce';
import { useStickyState } from '../../hooks/useStickyState';
import { IApplicationState as AppState } from '../../store';
import {
  IChatConfigAvatarProps,
  IChatConfigImageProps
} from '../../store/ducks/chats/types';
import { replaceTags } from '../../store/ducks/chats/utils';
import { AvatarButton, AvatarContainer, Badge, ChatBubble } from './styles';

export type ChatPosition =
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right';

interface IProps {
  chatId?: string;
  size?: number;
  type?: 'bot' | 'user' | 'initial' | 'collapse';
  error?: boolean;
  position?: ChatPosition;
  colorChat?: string;
  margins?: {
    x: number;
    y: number;
  };
  onClick?: () => void;
}

const imageBaseUrl = `${process.env.REACT_APP_AZURE_STORAGE_DNS}/avatars`;

const CustomAvatar: React.FC<IProps> = ({
  chatId,
  size = 40,
  type = 'bot',
  error = false,
  position = 'bottom left',
  margins,
  colorChat,
  onClick
}: IProps) => {
  const tags = useSelector((state: AppState) => state.chats.tags);
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);

  const [clickedInSession, setClickedInSession] = useStickyState(
    false,
    chatId ? `@CHATSALES:APP:ClickedInSession:${chatId}` : '',
    'memoryStorage'
  );

  const [clickedOnce, setClickedOnce] = useStickyState(
    false,
    chatId ? `@CHATSALES:APP:Clicked:${chatId}` : ''
  );

  const { config, defaultBot, defaultUser } = useAvatar();
  const debounceClick = useDebounce(clicked, 1);

  const badgeCount = useMemo(() => {
    const showCount =
      !clickedInSession &&
      (!clickedOnce || config?.always_show_initial_message);
    if (!config?.badge_count || !showCount) {
      return 0;
    }
    const showInitialCount = !clickedInSession && !clickedOnce;
    return !showInitialCount &&
      config.second_message?.badge_count &&
      config.second_message?.active
      ? config.second_message?.badge_count
      : config.badge_count;
  }, [clickedInSession, clickedOnce, config]);

  const getMessageWithReplacedTags = useCallback(
    message => {
      const replacedMessage = replaceTags(
        message,
        [
          ...tags,
          {
            key: '*|NOME_ATENDENTE|*',
            value: config?.attendant_name ?? ''
          }
        ],
        {
          allowEmptyReplace: true,
          errorOnReplaceProblem: !config?.second_message?.use_without_tags
        }
      );
      return replacedMessage;
    },
    [config, tags]
  );

  const message = useMemo(() => {
    const showMessage =
      !clickedInSession &&
      (!clickedOnce || config?.always_show_initial_message);
    if (!config?.initial_message || !showMessage) {
      return '';
    }
    const showInitialMessage = !clickedInSession && !clickedOnce;
    if (
      !showInitialMessage &&
      config.second_message?.message &&
      config.second_message?.active
    ) {
      const messageBackReplaced = getMessageWithReplacedTags(
        config.second_message.message
      );
      if (typeof messageBackReplaced === 'string') {
        return messageBackReplaced;
      }
    }
    return config.initial_message;
  }, [clickedInSession, clickedOnce, config, getMessageWithReplacedTags]);

  const botAvatarConfig = useMemo<IChatConfigAvatarProps>(() => {
    const baseConfig = JSON.parse(
      JSON.stringify(config?.bot_avatar?.avatar_config ?? defaultBot)
    ) as IChatConfigAvatarProps;

    if (type === 'initial') {
      if (clicked) {
        baseConfig.eye_type = 'Wink';
        baseConfig.eyebrow_type = 'UpDownNatural';
        baseConfig.mouth_type = 'Smile';
      }
      if (hover) {
        baseConfig.mouth_type = 'Smile';
      }
    } else if (error) {
      baseConfig.eye_type = 'Squint';
      baseConfig.eyebrow_type = 'SadConcerned';
      baseConfig.mouth_type = 'Sad';
    }

    return baseConfig;
  }, [clicked, config, defaultBot, error, hover, type]);

  const botAvatarImage = useMemo<IChatConfigImageProps | undefined>(() => {
    if (
      config?.bot_avatar?.default === 'image' &&
      config?.bot_avatar?.image_config
    ) {
      if (config.bot_avatar.image_config.image_name) {
        return {
          ...config.bot_avatar.image_config,
          image_url: `${imageBaseUrl}/${config.bot_avatar.image_config.image_name}`
        };
      }
      if (config?.bot_avatar?.image_config.image_url) {
        return config.bot_avatar.image_config;
      }
    }

    return undefined;
  }, [config]);

  const userAvatarConfig = useMemo<IChatConfigAvatarProps>(() => {
    return config?.client_avatar?.avatar_config ?? defaultUser;
  }, [config, defaultUser]);

  const userAvatarImage = useMemo<IChatConfigImageProps | undefined>(() => {
    if (
      config?.client_avatar?.default === 'image' &&
      config?.client_avatar?.image_config
    ) {
      if (config.client_avatar.image_config.image_name) {
        return {
          ...config.client_avatar.image_config,
          image_url: `${imageBaseUrl}/${config.client_avatar.image_config.image_name}`
        };
      }
      if (config?.client_avatar?.image_config.image_url) {
        return config.client_avatar.image_config;
      }
    }

    return undefined;
  }, [config]);

  const avatar = useMemo(() => {
    return type === 'user' ? userAvatarConfig : botAvatarConfig;
  }, [botAvatarConfig, type, userAvatarConfig]);

  const image = useMemo(() => {
    return type === 'user' ? userAvatarImage : botAvatarImage;
  }, [botAvatarImage, type, userAvatarImage]);

  const handleClick = useCallback(() => {
    setClicked(true);
    setClickedOnce(true);
    setClickedInSession(true);
  }, [setClickedInSession, setClickedOnce]);

  useEffect(() => {
    if (debounceClick) {
      setHover(false);
      setClicked(false);
      if (onClick) onClick();
    }
  }, [debounceClick, onClick]);

  const avatarBackground = useMemo(() => {
    if (avatar.style === 'Circle') {
      return `${mix(0.7, config?.color_chat || 'rgb(64, 157, 202)', '#fff')}`;
    }
    return 'transparent';
  }, [avatar.style, config?.color_chat]);

  const avatarElement = image ? (
    <></>
  ) : (
    <Avataaar
      style={{
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
        background: `${avatarBackground}`,
        borderRadius: `${avatar.style === 'Circle' ? '50%' : '0'}`
      }}
      avatarStyle="Transparent"
      topType={avatar.top ?? 'ShortHairShortCurly'}
      accessoriesType={avatar.accessories ?? 'Blank'}
      hairColor={avatar.hair_color ?? 'Black'}
      facialHairType={avatar.facial_hair ?? 'BeardLight'}
      facialHairColor={avatar.facial_hair_color ?? 'BrownDark'}
      clotheType={avatar.clothes ?? 'BlazerSweater'}
      clotheColor={avatar.clothe_color ?? 'Black'}
      graphicType={avatar.graphic_type ?? 'Bat'}
      eyeType={avatar.eye_type ?? 'Default'}
      eyebrowType={avatar.eyebrow_type ?? 'DefaultNatural'}
      mouthType={avatar.mouth_type ?? 'Twinkle'}
      skinColor={avatar.skin_color ?? 'Light'}
    />
  );

  if (type === 'initial' || type === 'collapse') {
    return (
      <>
        <AvatarButton
          type="button"
          size={size}
          avatarType={type}
          allowAnimation={!clickedInSession}
          image={image}
          onClick={handleClick}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          colorChat={colorChat}
        >
          {type === 'collapse' ? (
            <>
              {position === 'top left' || position === 'top right' ? (
                <MdExpandLess />
              ) : (
                <MdExpandMore />
              )}
            </>
          ) : (
            <>
              {avatarElement}
              {!!badgeCount && <Badge className="shadow-2">{badgeCount}</Badge>}
            </>
          )}
        </AvatarButton>
        {message && type !== 'collapse' && (
          <ChatBubble
            position={position}
            size={size}
            margins={margins || { y: 0, x: 0 }}
          >
            <div className="shadow-2 bubble">{message}</div>
            <div className="arrow">&nbsp;</div>
          </ChatBubble>
        )}
      </>
    );
  }

  return (
    <AvatarContainer
      avatarType={type}
      image={image}
      size={size}
      allowAnimation={false}
    >
      {avatarElement}
    </AvatarContainer>
  );
};

export default CustomAvatar;
