import { darken } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';

export const modalRootId = 'modal-root';

export const ModalRootStyle = createGlobalStyle`
  #${modalRootId} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background: rgba(0,0,0, 0.5);
  };
`;

export const Container = styled.div`
  max-width: 90%;
  background: #fff;
  margin: auto;
  border-radius: 4px;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  color: rgba(0, 0, 0, 0.6);
  padding: 24px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
  width: 100%;
  padding: 0 12px;
  background: #ededed;

  button {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    border: none;
    border-radius: 4px;
    background: #ededed;
    color: rgba(0, 0, 0, 0.72);
    font-size: 13px;
    line-height: 1;
    font-weight: 600;

    &:hover {
      background: ${darken(0.08, '#ededed')};
    }

    &.secondary-button {
      margin-right: auto;
    }
  }
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

interface IConfirmButton {
  color?: string;
}

export const ConfirmButtonWrapper = styled.div<IConfirmButton>`
  .confirm-button {
    background: ${props => props.color ?? '#409dca'};
    color: #fff;

    &:hover {
      background: ${props => darken(0.08, props.color ?? '#409dca')};
    }
  }
`;
