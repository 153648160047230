import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

export const modalRootId = 'modal-root-image';

const openModalAnimated = keyframes`
    from { transform:scale(0.4) }
    to { transform:scale(1) }
`;

export const ModalRootStyle = createGlobalStyle`
  #${modalRootId} {
    position: fixed;
    width: 100%;
    height: 100%;

    display: block;
    z-index: 2147483647;
    margin: 0 auto;

    overflow: auto;
    background-color: rgba(0,0,0,0.9);
  };
`;

interface IProps {
  openModal?: boolean;
}

export const Container = styled.div<IProps>`
  ${({ openModal }) =>
    openModal &&
    css`
      cursor: zoom-out;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      overflow: auto;

      img {
        animation-name: ${openModalAnimated};
        animation-duration: 0.6s;

        width: 100%;
      }
    `}

  ${({ openModal }) =>
    !openModal &&
    css`
      cursor: zoom-in;

      img {
        border-radius: 13px;
        max-width: 100%;
      }
    `}
`;
