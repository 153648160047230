import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  #chatsales-widget, .app-shadow {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
  #chatsales-widget[data-fullscreen="true"], #chatsales-widget[data-fullscreen="true"] .app-shadow {
    width: 100%;
    height: 100%;
  }
`;
