import { AnyAction } from 'redux';

/**
 * Actions
 */
export enum ChatTypes {
  ADD_ANSWER_SUCCESS = '@chat/ADD_ANSWER_SUCCESS',
  ADD_OPTION_ANSWER = '@chat/ADD_OPTION_ANSWER',
  ADD_QUESTION_REQUEST = '@chat/ADD_QUESTION_REQUEST',
  ADD_QUESTION_ANSWER = '@chat/ADD_QUESTION_ANSWER',
  ADD_TIMELINE_ITEM_REQUEST = '@chat/ADD_TIMELINE_ITEM_REQUEST',
  ADD_TIMELINE_ITEM_SUCCESS = '@chat/ADD_TIMELINE_ITEM_SUCCESS',
  CLEAR_CHAT = '@chat/CLEAR_CHAT',
  CLEAR_STORAGE = '@chat/CLEAR_STORAGE',
  CLOSE_ZENDESK_CHAT = '@chat/CLOSE_ZENDESK_CHAT',
  EXECUTE_ACTION = '@chat/EXECUTE_ACTION',
  FETCH_NEXT_SCRIPT_ITEM = '@chat/FETCH_NEXT_SCRIPT_ITEM',
  LOAD_CHAT_REQUEST = '@chat/LOAD_CHAT_REQUEST',
  LOAD_CHAT_FAILURE = '@chat/LOAD_CHAT_FAILURE',
  LOAD_CHAT_SUCCESS = '@chat/LOAD_CHAT_SUCCESS',
  LOAD_EXTERNAL_INFO_REQUEST = '@chat/LOAD_EXTERNAL_INFO_REQUEST',
  LOAD_EXTERNAL_INFO_SUCCESS = '@chat/LOAD_EXTERNAL_INFO_SUCCESS',
  OPEN_ZENDESK_CHAT = '@chat/OPEN_ZENDESK_CHAT',
  REDIRECT_TO = '@chat/REDIRECT_TO',
  REMOVE_CHAT = '@chat/REMOVE_CHAT',
  RENDER_CHAT = '@chat/RENDER_CHAT',
  RESTART_CHAT = '@chat/RESTART_CHAT',
  SAVE_INTERACTION = '@chat/SAVE_INTERACTION',
  SET_AWAIT_TO_RENDER = '@chat/SET_AWAIT_TO_RENDER',
  SET_CHAT_AS_REMOVED = '@chat/SET_CHAT_AS_REMOVED',
  SET_CHAT_AS_FINISHED = '@chat/SET_CHAT_AS_FINISHED',
  SET_ERROR = '@chat/SET_ERROR',
  SET_USER_INTERACTED = '@chat/SET_USER_INTERACTED',
  SET_ZENDESK_CHAT_AS_OPEN = '@chat/SET_ZENDESK_CHAT_AS_OPEN',
  SET_ZENDESK_INITIAL_MESSAGE_AS_SENT = '@chat/SET_ZENDESK_INITIAL_MESSAGE_AS_SENT'
}

/**
 * Action Payloads
 */
export interface IAddAnswerSuccess {
  timelineItem: ITimeline | null;
  nextItem: string;
  tag?: ITag;
}

export interface IAddOptionAnswer {
  id: string;
  option: IFlowOptionData | null;
  tag?: string;
}

export interface IAddQuestionAnswer {
  answer: string;
}

export interface IAddQuestionRequest {
  question: IFlowItem;
}

export interface IAddTimelineItemRequest {
  flowItem: IFlowItem;
}

export interface IAddTimelineItemSuccess {
  timelineItem: ITimeline;
  nextItem: string;
}

export interface IRenderChat {
  render_delay: number;
}

export interface IExecuteAction {
  action: IFlowData;
}

export interface ILoadChatRequest {
  chat: IChat;
}

export interface ILoadChatSuccess {
  id: string;
  name: string;
  active: boolean;
  config?: IChatConfig;
  flow: IFlowItem[];
}

export interface ILoadExternalInfoRequest {
  flow: IFlowItem[];
}

export interface ILoadExternalInfoSuccess {
  tags: ITag[];
}

export interface IRedirectTo {
  url: string;
  openNewTab: boolean;
}

export interface IZendeskTag {
  value: string;
  id: string;
}

export interface IZendeskTags {
  custom: IZendeskTag[];
  chatbot: IZendeskTag[];
}

export interface IZendeskData {
  zendeskKey: string;
  department: number;
  tags: IZendeskTags;
}

export interface ISetAwaitToRender {
  awaitToRender: boolean;
}

export interface ISetChatAsRemoved {
  removed: boolean;
}

export interface ISetError {
  error: string | null;
  type?: ErrorType;
}

export interface ISetZendeskChatAsOpen {
  open: boolean;
}

export interface ISetZendeskInitialMessageAsSent {
  sent: string;
}

export interface IRestartChat {
  chat: IChat;
}

/**
 * Action Types
 */
export interface IAddAnswerSuccessAction extends AnyAction {
  type: typeof ChatTypes.ADD_ANSWER_SUCCESS;
  payload?: IAddAnswerSuccess;
}

export interface IAddOptionAnswerAction extends AnyAction {
  type: typeof ChatTypes.ADD_OPTION_ANSWER;
  payload?: IAddOptionAnswer;
}

export interface IAddQuestionAnswerAction extends AnyAction {
  type: typeof ChatTypes.ADD_QUESTION_ANSWER;
  payload?: IAddQuestionAnswer;
}

export interface IAddQuestionRequestAction extends AnyAction {
  type: typeof ChatTypes.ADD_QUESTION_REQUEST;
  payload?: IAddQuestionRequest;
}

export interface IAddTimelineItemRequestAction extends AnyAction {
  type: typeof ChatTypes.ADD_TIMELINE_ITEM_REQUEST;
  payload?: IAddTimelineItemRequest;
}

export interface IAddTimelineItemSuccessAction extends AnyAction {
  type: typeof ChatTypes.ADD_TIMELINE_ITEM_SUCCESS;
  payload?: IAddTimelineItemSuccess;
}

export interface IClearStorageAction extends AnyAction {
  type: typeof ChatTypes.CLEAR_STORAGE;
}

export interface IExecuteActionAction extends AnyAction {
  type: typeof ChatTypes.EXECUTE_ACTION;
  payload?: IExecuteAction;
}

export interface IFetchNextScriptItemAction extends AnyAction {
  type: typeof ChatTypes.FETCH_NEXT_SCRIPT_ITEM;
}

export interface ILoadChatFailureAction extends AnyAction {
  type: typeof ChatTypes.LOAD_CHAT_FAILURE;
}

export interface ILoadChatRequestAction extends AnyAction {
  type: typeof ChatTypes.LOAD_CHAT_REQUEST;
  payload?: ILoadChatRequest;
}

export interface ILoadChatSuccessAction extends AnyAction {
  type: typeof ChatTypes.LOAD_CHAT_SUCCESS;
  payload?: ILoadChatSuccess;
}

export interface ILoadExternalInfoRequestAction extends AnyAction {
  type: typeof ChatTypes.LOAD_EXTERNAL_INFO_REQUEST;
  payload?: ILoadExternalInfoRequest;
}

export interface ILoadExternalInfoSuccessAction extends AnyAction {
  type: typeof ChatTypes.LOAD_EXTERNAL_INFO_SUCCESS;
  payload?: ILoadExternalInfoSuccess;
}

export interface ISaveInteractionAction extends AnyAction {
  type: typeof ChatTypes.SAVE_INTERACTION;
}

export interface ISetChatAsClosedAction extends AnyAction {
  type: typeof ChatTypes.SET_CHAT_AS_REMOVED;
}

export interface ISetChatAsFinishedAction extends AnyAction {
  type: typeof ChatTypes.SET_CHAT_AS_FINISHED;
}

export interface IRestartChatAction extends AnyAction {
  type: typeof ChatTypes.RESTART_CHAT;
  payload?: IRestartChat;
}

export interface ISetAwaitToRenderAction extends AnyAction {
  type: typeof ChatTypes.SET_AWAIT_TO_RENDER;
  payload?: ISetAwaitToRender;
}

export interface ISetChatAsRemovedAction extends AnyAction {
  type: typeof ChatTypes.SET_CHAT_AS_REMOVED;
  payload?: ISetChatAsRemoved;
}

export interface ISetErrorAction extends AnyAction {
  type: typeof ChatTypes.SET_ERROR;
  payload?: ISetError;
}

export interface ISetUserInteractedAction extends AnyAction {
  type: typeof ChatTypes.SET_USER_INTERACTED;
}

export interface ISetZendeskChatAsOpenAction extends AnyAction {
  type: typeof ChatTypes.SET_ZENDESK_CHAT_AS_OPEN;
  payload?: ISetZendeskChatAsOpen;
}

export interface ISetZendeskInitialMessageAsSentAction extends AnyAction {
  type: typeof ChatTypes.SET_ZENDESK_INITIAL_MESSAGE_AS_SENT;
  payload?: ISetZendeskInitialMessageAsSent;
}

/**
 * Chat Action Types
 */
export type ChatActionTypes =
  | IAddAnswerSuccessAction
  | IAddOptionAnswerAction
  | IAddQuestionAnswerAction
  | IAddQuestionRequestAction
  | IAddTimelineItemRequestAction
  | IAddTimelineItemSuccessAction
  | IClearStorageAction
  | IExecuteActionAction
  | IFetchNextScriptItemAction
  | ILoadChatFailureAction
  | ILoadChatRequestAction
  | ILoadChatSuccessAction
  | ILoadExternalInfoRequestAction
  | ILoadExternalInfoSuccessAction
  | IRestartChatAction
  | ISaveInteractionAction
  | ISetChatAsFinishedAction
  | ISetChatAsRemovedAction
  | ISetErrorAction
  | ISetUserInteractedAction
  | ISetZendeskChatAsOpenAction
  | ISetZendeskInitialMessageAsSentAction;

/**
 * Data Type Enums
 */
export enum FlowItemType {
  ACTION = 'ACTION',
  ANSWER = 'ANSWER',
  ERROR = 'ERROR',
  IMAGE = 'IMAGE',
  LOADING = 'LOADING',
  OPTIONS = 'OPTIONS',
  QUESTION = 'QUESTION',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

export enum FieldType {
  text = 'text',
  email = 'email',
  phone = 'phone'
}

export enum ErrorType {
  NotFound = 404,
  BadRequest = 400,
  InternalServerError = 500
}

/**
 * Data Types
 */
export interface IApiAction {
  id: string;
  description?: string;
  url: string;
  method: 'delete' | 'get' | 'patch' | 'post' | 'put';
  machine?: number;
  funnel?: number;
  level?: number;
  score?: number;
  tags?: Array<string>;
  data?: Array<{
    id?: string;
    tag: string;
    prop: string;
  }>;
}
export interface IAppAction {
  id: string;
  redirect?: {
    url: string;
    openNewTab: boolean;
  };
}
export type IAction = IApiAction | IAppAction;

export interface IChat {
  id: string;
  name: string;
  active: boolean;
  flow: IFlowItem[];
  config?: IChatConfig;
}

export interface IChatConfig {
  attendant_name?: string;
  initial_message?: string;
  color_chat?: string;
  badge_count?: number;
  bot_avatar?: IChatConfigAvatar;
  client_avatar?: IChatConfigAvatar;
  visibility?: IChatConfigVisibility;
  always_show_initial_message?: boolean;
  second_message?: IChatConfigSecondMessage;
  brand?: IChatConfigBrand;
  render_delay?: number;
  behavior_when_close_chat?: IChatConfigBehaviorWhenClose;
}

export interface IChatConfigAvatar {
  default?: 'avatar' | 'image';
  image_config?: IChatConfigImageProps;
  avatar_config?: IChatConfigAvatarProps;
}

export interface IChatConfigAvatarProps {
  style?: 'Circle' | 'Transparent';

  top?:
    | 'NoHair'
    | 'Eyepatch'
    | 'Hat'
    | 'Hijab'
    | 'Turban'
    | 'WinterHat1'
    | 'WinterHat2'
    | 'WinterHat3'
    | 'WinterHat4'
    | 'LongHairBigHair'
    | 'LongHairBob'
    | 'LongHairBun'
    | 'LongHairCurly'
    | 'LongHairCurvy'
    | 'LongHairDreads'
    | 'LongHairFrida'
    | 'LongHairFro'
    | 'LongHairFroBand'
    | 'LongHairNotTooLong'
    | 'LongHairShavedSides'
    | 'LongHairMiaWallace'
    | 'LongHairStraight'
    | 'LongHairStraight2'
    | 'LongHairStraightStrand'
    | 'ShortHairDreads01'
    | 'ShortHairDreads02'
    | 'ShortHairFrizzle'
    | 'ShortHairShaggyMullet'
    | 'ShortHairShortCurly'
    | 'ShortHairShortFlat'
    | 'ShortHairShortRound'
    | 'ShortHairShortWaved'
    | 'ShortHairSides'
    | 'ShortHairTheCaesar'
    | 'ShortHairTheCaesarSidePart';

  accessories?:
    | 'Blank'
    | 'Kurt'
    | 'Prescription01'
    | 'Prescription02'
    | 'Round'
    | 'Sunglasses'
    | 'Wayfarers';

  hat_color?:
    | 'Black'
    | 'Blue01'
    | 'Blue02'
    | 'Blue03'
    | 'Gray01'
    | 'Gray02'
    | 'Heather'
    | 'PastelBlue'
    | 'PastelGreen'
    | 'PastelOrange'
    | 'PastelRed'
    | 'PastelYellow'
    | 'Pink'
    | 'Red'
    | 'White';

  hair_color?:
    | 'Auburn'
    | 'Black'
    | 'Blonde'
    | 'BlondeGolden'
    | 'Brown'
    | 'BrownDark'
    | 'PastelPink'
    | 'Platinum'
    | 'Red'
    | 'SilverGray';

  facial_hair?:
    | 'Blank'
    | 'BeardMedium'
    | 'BeardLight'
    | 'BeardMagestic'
    | 'MoustacheFancy'
    | 'MoustacheMagnum';

  facial_hair_color?:
    | 'Auburn'
    | 'Black'
    | 'Blonde'
    | 'BlondeGolden'
    | 'Brown'
    | 'BrownDark'
    | 'Platinum'
    | 'Red';

  clothes?:
    | 'BlazerShirt'
    | 'BlazerSweater'
    | 'CollarSweater'
    | 'GraphicShirt'
    | 'Hoodie'
    | 'Overall'
    | 'ShirtCrewNeck'
    | 'ShirtScoopNeck'
    | 'ShirtVNeck';

  clothe_color?:
    | 'Black'
    | 'Blue01'
    | 'Blue02'
    | 'Blue03'
    | 'Gray01'
    | 'Gray02'
    | 'Heather'
    | 'PastelBlue'
    | 'PastelGreen'
    | 'PastelOrange'
    | 'PastelRed'
    | 'PastelYellow'
    | 'Pink'
    | 'Red'
    | 'White';

  graphic_type?:
    | 'Bat'
    | 'Cumbia'
    | 'Deer'
    | 'Diamond'
    | 'Hola'
    | 'Pizza'
    | 'Resist'
    | 'Selena'
    | 'Bear'
    | 'SkullOutline'
    | 'Skull';

  eye_type?:
    | 'Close'
    | 'Cry'
    | 'Default'
    | 'Dizzy'
    | 'EyeRoll'
    | 'Happy'
    | 'Hearts'
    | 'Side'
    | 'Squint'
    | 'Surprised'
    | 'Wink'
    | 'WinkWacky';

  eyebrow_type?:
    | 'Angry'
    | 'AngryNatural'
    | 'Default'
    | 'DefaultNatural'
    | 'FlatNatural'
    | 'RaisedExcited'
    | 'RaisedExcitedNatural'
    | 'SadConcerned'
    | 'SadConcernedNatural'
    | 'UnibrowNatural'
    | 'UpDown'
    | 'UpDownNatural';

  mouth_type?:
    | 'Concerned'
    | 'Default'
    | 'Disbelief'
    | 'Eating'
    | 'Grimace'
    | 'Sad'
    | 'ScreamOpen'
    | 'Serious'
    | 'Smile'
    | 'Tongue'
    | 'Twinkle'
    | 'Vomit';

  skin_color?:
    | 'Tanned'
    | 'Yellow'
    | 'Pale'
    | 'Light'
    | 'Brown'
    | 'DarkBrown'
    | 'Black';
}

export interface IChatConfigImageProps {
  image_name?: string;
  image_url?: string;
  border_radius?: number;
  background_color?: string;
}

export interface IChatConfigVisibility {
  blacklisted_urls: string[];
  whitelisted_urls: string[];
}

export interface IChatConfigSecondMessage {
  active: boolean;
  message: string;
  badge_count: number;
  use_without_tags: boolean;
}

export interface IChatConfigBrand {
  active: boolean;
  text: string;
  image_url: string;
  redirect_url: string;
}

export interface IChatConfigBehaviorWhenClose {
  type: 'never-show-again' | 'always-show' | 'reset';
}

export interface IDynamicField {
  id: number;
  type: IDynamicFieldType;
  status: boolean;
  name: string;
  label: string;
  tag: string;
  registerDate: Date;
  options: Array<IDynamicFieldOption>;
}

export interface IDynamicFieldOption {
  id: number;
  dynamicFieldId: number;
  status: boolean;
  value: string;
  score: number;
  registerDate: Date;
}

export type IDynamicFieldType =
  | 'cpf'
  | 'cpf-cnpj'
  | 'cnpj'
  | 'date'
  | 'email'
  | 'radio'
  | 'checkbox'
  | 'select'
  | 'phone'
  | 'textarea'
  | 'text';

export interface IError {
  message: string;
  type: ErrorType;
}

export interface IFlowApiAction {
  description?: string;
  url: string;
  method: 'delete' | 'get' | 'patch' | 'post' | 'put';
  product?: number;
  machine?: number;
  funnel?: number;
  level?: number;
  score?: number;
  tags?: Array<string>;
  data?: Array<{
    tag: string;
    prop: string;
  }>;
}

export interface IFlowAppAction {
  redirect?: {
    url: string;
    openNewTab: boolean;
  };
  zendeskData?: {
    zendeskKey: string;
    department: number;
    tags: IZendeskTags;
  };
  script?: string
}

export type IFlowAction = IFlowApiAction | IFlowAppAction;

export type IExternalInfoScriptData = {
  description: string;
  variable: string;
  valueType: 'text' | 'email' | 'phone' | 'name';
};

export type IExternalInfoData = {
  externalInfoType: 'scriptInfo' | 'parameterInfo';
  scriptInfo?: IExternalInfoScriptData;
  tag: string;
};

export type SetterState<T> = React.Dispatch<React.SetStateAction<T>>;

export type ManipulateInputMask = (
  value: string,
  setMask: SetterState<string>,
  answerType?: 'text' | 'email' | 'phone' | 'name' | IDynamicFieldType
) => void;

export interface IFlowData {
  message?: string;
  delay?: string;
  image?: string;
  video?: string;
  tag?: string;
  placeholder?: string;
  answerType?: 'text' | 'email' | 'phone' | 'name';
  dynamicField?: IDynamicField;
  options?: IFlowOptions;
  action?: IFlowAction;
  sourceConnectionId?: string;
  targetConnectionId?: string;
  isInitialMessage?: boolean;
  mask?: string;
  defaultValue: string;
  externalInfo?: IExternalInfoData;
}

export interface IFlowItem {
  id: string;
  type: string;
  source?: string;
  target?: string;
  data?: IFlowData;
  position?: {
    x: number;
    y: number;
  };
}

export interface IFlowOptions {
  [key: string]: IFlowOptionData;
}

export interface IFlowOptionData {
  id: string;
  message: string;
  link?: string;
  sourceConnectionId?: string;
}

export interface ITag {
  key: string;
  value: string;
}

export interface ITimeline {
  id: string;
  type: FlowItemType;
  content: string;
  last: boolean;
  tag?: string;
  options?: IFlowOptions;
  dynamicType?: IDynamicFieldType;
  redirect?: IRedirectTo;
  zendeskChat?: IZendeskData;
  createScript?: string;

  // flow?: IFlowItem;
}

export interface IValidationResult {
  valid: boolean;
  error?: string;
}

/**
 * State Types
 */
export interface IChatState {
  readonly id: string;
  readonly name: string;
  readonly active: boolean;
  readonly config: IChatConfig | null;
  readonly error: IError | null;
  readonly loading: boolean;
  readonly question: IFlowItem | null;
  readonly flow: IFlowItem[];
  readonly nextItem: string | null;
  readonly tags: ITag[];
  readonly timeline: ITimeline[];
  readonly finished: boolean;
  readonly removed: boolean | Date;
  readonly zendeskChatOpen: boolean;
  readonly zendeskInitialMessageSent: string;
  readonly awaitToRender: boolean;
  readonly userInteracted: boolean;
}
