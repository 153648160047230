import React from 'react';
import { Container, Pointer } from './styles';

export interface IProps {
  size?: number | string;
  color?: string;
  margin?: number | string;
}

const Loader: React.FC<IProps> = ({ size, color, margin }) => {
  return (
    <Container size={size ?? 16} color={color ?? '#000'} margin={margin ?? 1}>
      <Pointer nth={1} />
      <Pointer nth={2} />
      <Pointer nth={3} />
    </Container>
  );
};

export default Loader;
