import React from 'react';
import { Provider } from 'react-redux';
import root from 'react-shadow/styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import Chat from './components/Chat';
import { ModalRootImage } from './components/ImageModal';
import { AvatarProvider } from './hooks/avatar';
import { ConfirmModalProvider } from './hooks/useConfirmModal';
import { ImageModalProvider } from './hooks/useImageModal';
import { ZendeskProvider } from './hooks/useZendesk';
import { persistor, store } from './store';
import GlobalStyle from './styles/global';
import OuterShadowStyle from './styles/outerShadow';

const App: React.FC = () => (
  <>
    <root.div className="app-shadow">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AvatarProvider>
            <ImageModalProvider>
              <ConfirmModalProvider>
                <ZendeskProvider>
                  <ModalRootImage />
                  <Chat />
                </ZendeskProvider>
                <GlobalStyle />
              </ConfirmModalProvider>
            </ImageModalProvider>
          </AvatarProvider>
        </PersistGate>
      </Provider>
    </root.div>
    <OuterShadowStyle />
  </>
);

export default App;
