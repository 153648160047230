import React, { useState, createContext, useContext, useCallback } from 'react';
import ImageModal, { PortalModalImage } from '../components/ImageModal';

interface ImageModalContextData {
  switchImageModal(imgUrl: string | null): void;
  statusModal: boolean;
}

const ImageModalContext = createContext<ImageModalContextData>(
  {} as ImageModalContextData
);

const ImageModalProvider: React.FC = ({ children }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const handleSwitchModal = useCallback(
    (imgUrl: string | null) => {
      if (!imgUrl) return setImageUrl(null);

      setOpen(!open);

      return setImageUrl(imgUrl);
    },
    [open]
  );

  return (
    <ImageModalContext.Provider
      value={{
        switchImageModal: handleSwitchModal,
        statusModal: open
      }}
    >
      {children}
      {imageUrl && open && (
        <PortalModalImage>
          <ImageModal imageUrl={imageUrl} />
        </PortalModalImage>
      )}
    </ImageModalContext.Provider>
  );
};

function useImageModal(): ImageModalContextData {
  const context = useContext(ImageModalContext);
  if (!context) {
    throw new Error('useImageModal must be used within an ImageModalProvider');
  }
  return context;
}

export { ImageModalProvider, useImageModal };
